//
// Popovers
// --------------------------------------------------


.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--ion-zindex-popover);
  display: none;
  max-width: var(--ion-popover-max-width);
  padding: 1px;
  // Our parent element can be arbitrary since popovers are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text;
  font-size: var(--ion-font-size-base);
  background-color: var(--ion-popover-bg);
  background-clip: padding-box;
  border: 1px solid var(--ion-popover-fallback-border-color);
  border: 1px solid var(--ion-popover-border-color);
  border-radius: var(--ion-border-radius-large);
  @include box-shadow(0 5px 10px rgba(0, 0, 0, .2));

  // Offset the popover to account for the popover arrow
  &.top { margin-top: -var(--ion-popover-arrow-width); }
  &.right { margin-left: var(--ion-popover-arrow-width); }
  &.bottom { margin-top: var(--ion-popover-arrow-width); }
  &.left { margin-left: -var(--ion-popover-arrow-width); }

  // Arrows
  // .arrow is outer, .arrow:after is inner
  > .arrow {
    border-width: var(--ion-popover-arrow-outer-width);

    &,
    &:after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
    }

    &:after {
      content: "";
      border-width: var(--ion-popover-arrow-width);
    }
  }

  &.top > .arrow {
    bottom: -var(--ion-popover-arrow-outer-width);
    left: 50%;
    margin-left: -var(--ion-popover-arrow-outer-width);
    border-top-color: var(--ion-popover-arrow-outer-fallback-color); // IE8 fallback
    border-top-color: var(--ion-popover-arrow-outer-color);
    border-bottom-width: 0;
    &:after {
      bottom: 1px;
      margin-left: -var(--ion-popover-arrow-width);
      content: " ";
      border-top-color: var(--ion-popover-arrow-color);
      border-bottom-width: 0;
    }
  }
  &.right > .arrow {
    top: 50%;
    left: -var(--ion-popover-arrow-outer-width);
    margin-top: -var(--ion-popover-arrow-outer-width);
    border-right-color: var(--ion-popover-arrow-outer-fallback-color); // IE8 fallback
    border-right-color: var(--ion-popover-arrow-outer-color);
    border-left-width: 0;
    &:after {
      bottom: -var(--ion-popover-arrow-width);
      left: 1px;
      content: " ";
      border-right-color: var(--ion-popover-arrow-color);
      border-left-width: 0;
    }
  }
  &.bottom > .arrow {
    top: -var(--ion-popover-arrow-outer-width);
    left: 50%;
    margin-left: -var(--ion-popover-arrow-outer-width);
    border-top-width: 0;
    border-bottom-color: var(--ion-popover-arrow-outer-fallback-color); // IE8 fallback
    border-bottom-color: var(--ion-popover-arrow-outer-color);
    &:after {
      top: 1px;
      margin-left: -var(--ion-popover-arrow-width);
      content: " ";
      border-top-width: 0;
      border-bottom-color: var(--ion-popover-arrow-color);
    }
  }

  &.left > .arrow {
    top: 50%;
    right: -var(--ion-popover-arrow-outer-width);
    margin-top: -var(--ion-popover-arrow-outer-width);
    border-right-width: 0;
    border-left-color: var(--ion-popover-arrow-outer-fallback-color); // IE8 fallback
    border-left-color: var(--ion-popover-arrow-outer-color);
    &:after {
      right: 1px;
      bottom: -var(--ion-popover-arrow-width);
      content: " ";
      border-right-width: 0;
      border-left-color: var(--ion-popover-arrow-color);
    }
  }
}

.popover-title {
  padding: 8px 14px;
  margin: 0; // reset heading margin
  font-size: var(--ion-font-size-base);
  background-color: var(--ion-popover-title-bg);
  border-bottom: 1px solid LightenDarkenColor(var(--ion-popover-title-bg), -5);
  border-radius: (var(--ion-border-radius-large) - 1) (var(--ion-border-radius-large) - 1) 0 0;
}

.popover-content {
  padding: 9px 14px;
}
