// Alerts

@mixin alert-variant($background, $border, $text-color) {
  color: $text-color;
  background-color: $background;
  border-color: $border;

  hr {
    border-top-color: LightenDarkenColor($border, -5);
  }

  .alert-link {
    color: LightenDarkenColor($text-color, -10);
  }
}
