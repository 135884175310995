@import "./modals";

.splash-open {
    @extend .modal;
}

.splash {
    @extend .modal-backdrop;
    visibility: hidden;
    @include opacity(0);
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    background-color: #fff;
    transition:(opacity 0.4s 0.4s, visibility 0s 1s);
}

.splash__content {
    text-align: center;

    img {
        width: 6em;
        @include opacity(0);
        @include translate(0,5%);
        transition:(transform 0.2s, opacity 0.2s);

        @media screen and (min-width: var(--ion-screen-sm-min)) {
            width: 8em;
        }
    }
}


.splash--visible {
    visibility: visible;
    @include opacity(1);
    transition:(opacity 0s, visibility 0s);

    img {
        @include opacity(1);
        @include scale(1);
        transition:(transform 0.2s 0.1s, opacity 0.2s 0.1s);
    }
}
