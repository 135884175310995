:root {
--ion-bootstrap-sass-asset-helper: false;
//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

--ion-gray-base:              #000;
--ion-gray-darker:            lighten(var(--ion-gray-base), 13.5%); // #222
--ion-gray-dark:              lighten(var(--ion-gray-base), 20%);   // #333
--ion-gray:                   lighten(var(--ion-gray-base), 33.5%); // #555
--ion-gray-light:             lighten(var(--ion-gray-base), 46.7%); // #777
--ion-gray-lighter:           lighten(var(--ion-gray-base), 93.5%); // #eee

--ion-brand-primary:         darken(#428bca, 6.5%); // #337ab7
--ion-brand-success:         #5cb85c;
--ion-brand-info:            #5bc0de;
--ion-brand-warning:         #f0ad4e;
--ion-brand-danger:          #d9534f;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
--ion-body-bg:               #fff;
//** Global text color on `<body>`.
--ion-text-color:            var(--ion-gray-dark);

//** Global textual link color.
--ion-link-color:            var(--ion-brand-primary);
//** Link hover color set via `darken()` function.
--ion-link-hover-color:      darken(var(--ion-link-color), 15%);
//** Link hover decoration.
--ion-link-hover-decoration: underline;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

--ion-font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
--ion-font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
--ion-font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
--ion-font-family-base:        var(--ion-font-family-sans-serif);

--ion-font-size-base:          14px;
--ion-font-size-large:         ceil((var(--ion-font-size-base) * 1.25)); // ~18px
--ion-font-size-small:         ceil((var(--ion-font-size-base) * .85)); // ~12px

--ion-font-size-h1:            floor((var(--ion-font-size-base) * 2.6)); // ~36px
--ion-font-size-h2:            floor((var(--ion-font-size-base) * 2.15)); // ~30px
--ion-font-size-h3:            ceil((var(--ion-font-size-base) * 1.7)); // ~24px
--ion-font-size-h4:            ceil((var(--ion-font-size-base) * 1.25)); // ~18px
--ion-font-size-h5:            var(--ion-font-size-base);
--ion-font-size-h6:            ceil((var(--ion-font-size-base) * .85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
--ion-line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
--ion-line-height-computed:    floor((var(--ion-font-size-base) * var(--ion-line-height-base))); // ~20px

//** By default, this inherits from the `<body>`.
--ion-headings-font-family:    inherit;
--ion-headings-font-weight:    500;
--ion-headings-line-height:    1.1;
--ion-headings-color:          inherit;


//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.

// [converter] If var(--ion-bootstrap-sass-asset-helper) if used, provide path relative to the assets load path.
// [converter] This is because some asset helpers, such as Sprockets, do not work with file-relative paths.
--ion-icon-font-path: if(var(--ion-bootstrap-sass-asset-helper), "bootstrap/", "../fonts/bootstrap/");

//** File name for all font files.
--ion-icon-font-name:          "glyphicons-halflings-regular";
//** Element ID within SVG icon file.
--ion-icon-font-svg-id:        "glyphicons_halflingsregular";


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

--ion-padding-base-vertical:     6px;
--ion-padding-base-horizontal:   12px;

--ion-padding-large-vertical:    10px;
--ion-padding-large-horizontal:  16px;

--ion-padding-small-vertical:    5px;
--ion-padding-small-horizontal:  10px;

--ion-padding-xs-vertical:       1px;
--ion-padding-xs-horizontal:     5px;

--ion-line-height-large:         1.3333333; // extra decimals for Win 8.1 Chrome
--ion-line-height-small:         1.5;

--ion-border-radius-base:        4px;
--ion-border-radius-large:       6px;
--ion-border-radius-small:       3px;

//** Global color for active items (e.g., navs or dropdowns).
--ion-component-active-color:    #fff;
//** Global background color for active items (e.g., navs or dropdowns).
--ion-component-active-bg:       var(--ion-brand-primary);

//** Width of the `border` for generating carets that indicate dropdowns.
--ion-caret-width-base:          4px;
//** Carets increase slightly in size for larger components.
--ion-caret-width-large:         5px;


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
--ion-table-cell-padding:            8px;
//** Padding for cells in `.table-condensed`.
--ion-table-condensed-cell-padding:  5px;

//** Default background color used for all tables.
--ion-table-bg:                      transparent;
//** Background color used for `.table-striped`.
--ion-table-bg-accent:               #f9f9f9;
//** Background color used for `.table-hover`.
--ion-table-bg-hover:                #f5f5f5;
--ion-table-bg-active:               var(--ion-table-bg-hover);

//** Border color for table and cell borders.
--ion-table-border-color:            #ddd;


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

--ion-btn-font-weight:                normal;

--ion-btn-default-color:              #333;
--ion-btn-default-bg:                 #fff;
--ion-btn-default-border:             #ccc;

--ion-btn-primary-color:              #fff;
--ion-btn-primary-bg:                 var(--ion-brand-primary);
--ion-btn-primary-border:             darken(var(--ion-btn-primary-bg), 5%);

--ion-btn-success-color:              #fff;
--ion-btn-success-bg:                 var(--ion-brand-success);
--ion-btn-success-border:             darken(var(--ion-btn-success-bg), 5%);

--ion-btn-info-color:                 #fff;
--ion-btn-info-bg:                    var(--ion-brand-info);
--ion-btn-info-border:                darken(var(--ion-btn-info-bg), 5%);

--ion-btn-warning-color:              #fff;
--ion-btn-warning-bg:                 var(--ion-brand-warning);
--ion-btn-warning-border:             darken(var(--ion-btn-warning-bg), 5%);

--ion-btn-danger-color:               #fff;
--ion-btn-danger-bg:                  var(--ion-brand-danger);
--ion-btn-danger-border:              darken(var(--ion-btn-danger-bg), 5%);

--ion-btn-link-disabled-color:        var(--ion-gray-light);

// Allows for customizing button radius independently from global border radius
--ion-btn-border-radius-base:         var(--ion-border-radius-base);
--ion-btn-border-radius-large:        var(--ion-border-radius-large);
--ion-btn-border-radius-small:        var(--ion-border-radius-small);


//== Forms
//
//##

//** `<input>` background color
--ion-input-bg:                       #fff;
//** `<input disabled>` background color
--ion-input-bg-disabled:              var(--ion-gray-lighter);

//** Text color for `<input>`s
--ion-input-color:                    var(--ion-gray);
//** `<input>` border color
--ion-input-border:                   #ccc;

// TODO: Rename `--ion-input-border-radius` to `--ion-input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
--ion-input-border-radius:            var(--ion-border-radius-base);
//** Large `.form-control` border radius
--ion-input-border-radius-large:      var(--ion-border-radius-large);
//** Small `.form-control` border radius
--ion-input-border-radius-small:      var(--ion-border-radius-small);

//** Border color for inputs on focus
--ion-input-border-focus:             #66afe9;

//** Placeholder text color
--ion-input-color-placeholder:        #999;

//** Default `.form-control` height
--ion-input-height-base:              (var(--ion-line-height-computed) + (var(--ion-padding-base-vertical) * 2) + 2);
//** Large `.form-control` height
--ion-input-height-large:             (ceil(var(--ion-font-size-large) * var(--ion-line-height-large)) + (var(--ion-padding-large-vertical) * 2) + 2);
//** Small `.form-control` height
--ion-input-height-small:             (floor(var(--ion-font-size-small) * var(--ion-line-height-small)) + (var(--ion-padding-small-vertical) * 2) + 2);

//** `.form-group` margin
--ion-form-group-margin-bottom:       15px;

--ion-legend-color:                   var(--ion-gray-dark);
--ion-legend-border-color:            #e5e5e5;

//** Background color for textual input addons
--ion-input-group-addon-bg:           var(--ion-gray-lighter);
//** Border color for textual input addons
--ion-input-group-addon-border-color: var(--ion-input-border);

//** Disabled cursor for form controls and buttons.
--ion-cursor-disabled:                not-allowed;


//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
--ion-dropdown-bg:                    #fff;
//** Dropdown menu `border-color`.
--ion-dropdown-border:                rgba(0, 0, 0, .15);
//** Dropdown menu `border-color` **for IE8**.
--ion-dropdown-fallback-border:       #ccc;
//** Divider color for between dropdown items.
--ion-dropdown-divider-bg:            #e5e5e5;

//** Dropdown link text color.
--ion-dropdown-link-color:            var(--ion-gray-dark);
//** Hover color for dropdown links.
--ion-dropdown-link-hover-color:      darken(var(--ion-gray-dark), 5%);
//** Hover background for dropdown links.
--ion-dropdown-link-hover-bg:         #f5f5f5;

//** Active dropdown menu item text color.
--ion-dropdown-link-active-color:     var(--ion-component-active-color);
//** Active dropdown menu item background color.
--ion-dropdown-link-active-bg:        var(--ion-component-active-bg);

//** Disabled dropdown menu item background color.
--ion-dropdown-link-disabled-color:   var(--ion-gray-light);

//** Text color for headers within dropdown menus.
--ion-dropdown-header-color:          var(--ion-gray-light);

//** Deprecated `--ion-dropdown-caret-color` as of v3.1.0
--ion-dropdown-caret-color:           #000;


//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

--ion-zindex-navbar:            1000;
--ion-zindex-dropdown:          1000;
--ion-zindex-popover:           1060;
--ion-zindex-tooltip:           1070;
--ion-zindex-navbar-fixed:      1030;
--ion-zindex-modal-background:  1040;
--ion-zindex-modal:             1050;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `--ion-screen-xs` as of v3.0.1
--ion-screen-xs:                  480px;
//** Deprecated `--ion-screen-xs-min` as of v3.2.0
--ion-screen-xs-min:              var(--ion-screen-xs);
//** Deprecated `--ion-screen-phone` as of v3.0.1
--ion-screen-phone:               var(--ion-screen-xs-min);

// Small screen / tablet
//** Deprecated `--ion-screen-sm` as of v3.0.1
--ion-screen-sm:                  768px;
--ion-screen-sm-min:              var(--ion-screen-sm);
//** Deprecated `--ion-screen-tablet` as of v3.0.1
--ion-screen-tablet:              var(--ion-screen-sm-min);

// Medium screen / desktop
//** Deprecated `--ion-screen-md` as of v3.0.1
--ion-screen-md:                  992px;
--ion-screen-md-min:              var(--ion-screen-md);
//** Deprecated `--ion-screen-desktop` as of v3.0.1
--ion-screen-desktop:             var(--ion-screen-md-min);

// Large screen / wide desktop
//** Deprecated `--ion-screen-lg` as of v3.0.1
--ion-screen-lg:                  1200px;
--ion-screen-lg-min:              var(--ion-screen-lg);
//** Deprecated `--ion-screen-lg-desktop` as of v3.0.1
--ion-screen-lg-desktop:          var(--ion-screen-lg-min);

// So media queries don't overlap when required, provide a maximum
--ion-screen-xs-max:              (var(--ion-screen-sm-min) - 1);
--ion-screen-sm-max:              (var(--ion-screen-md-min) - 1);
--ion-screen-md-max:              (var(--ion-screen-lg-min) - 1);


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
--ion-grid-columns:              12;
//** Padding between columns. Gets divided in half for the left and right.
--ion-grid-gutter-width:         30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
--ion-grid-float-breakpoint:     var(--ion-screen-sm-min);
//** Point at which the navbar begins collapsing.
--ion-grid-float-breakpoint-max: (var(--ion-grid-float-breakpoint) - 1);


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
--ion-container-tablet:             (720px + var(--ion-grid-gutter-width));
//** For `--ion-screen-sm-min` and up.
--ion-container-sm:                 var(--ion-container-tablet);

// Medium screen / desktop
--ion-container-desktop:            (940px + var(--ion-grid-gutter-width));
//** For `--ion-screen-md-min` and up.
--ion-container-md:                 var(--ion-container-desktop);

// Large screen / wide desktop
--ion-container-large-desktop:      (1140px + var(--ion-grid-gutter-width));
//** For `--ion-screen-lg-min` and up.
--ion-container-lg:                 var(--ion-container-large-desktop);


//== Navbar
//
//##

// Basics of a navbar
--ion-navbar-height:                    50px;
--ion-navbar-margin-bottom:             var(--ion-line-height-computed);
--ion-navbar-border-radius:             var(--ion-border-radius-base);
--ion-navbar-padding-horizontal:        floor((var(--ion-grid-gutter-width) / 2));
--ion-navbar-padding-vertical:          ((var(--ion-navbar-height) - var(--ion-line-height-computed) / 2));
--ion-navbar-collapse-max-height:       340px;

--ion-navbar-default-color:             #777;
--ion-navbar-default-bg:                #f8f8f8;
--ion-navbar-default-border:            darken(var(--ion-navbar-default-bg), 6.5%);

// Navbar links
--ion-navbar-default-link-color:                #777;
--ion-navbar-default-link-hover-color:          #333;
--ion-navbar-default-link-hover-bg:             transparent;
--ion-navbar-default-link-active-color:         #555;
--ion-navbar-default-link-active-bg:            darken(var(--ion-navbar-default-bg), 6.5%);
--ion-navbar-default-link-disabled-color:       #ccc;
--ion-navbar-default-link-disabled-bg:          transparent;

// Navbar brand label
--ion-navbar-default-brand-color:               var(--ion-navbar-default-link-color);
--ion-navbar-default-brand-hover-color:         darken(var(--ion-navbar-default-brand-color), 10%);
--ion-navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
--ion-navbar-default-toggle-hover-bg:           #ddd;
--ion-navbar-default-toggle-icon-bar-bg:        #888;
--ion-navbar-default-toggle-border-color:       #ddd;


//=== Inverted navbar
// Reset inverted navbar basics
--ion-navbar-inverse-color:                      lighten(var(--ion-gray-light), 15%);
--ion-navbar-inverse-bg:                         #222;
--ion-navbar-inverse-border:                     darken(var(--ion-navbar-inverse-bg), 10%);

// Inverted navbar links
--ion-navbar-inverse-link-color:                 lighten(var(--ion-gray-light), 15%);
--ion-navbar-inverse-link-hover-color:           #fff;
--ion-navbar-inverse-link-hover-bg:              transparent;
--ion-navbar-inverse-link-active-color:          var(--ion-navbar-inverse-link-hover-color);
--ion-navbar-inverse-link-active-bg:             darken(var(--ion-navbar-inverse-bg), 10%);
--ion-navbar-inverse-link-disabled-color:        #444;
--ion-navbar-inverse-link-disabled-bg:           transparent;

// Inverted navbar brand label
--ion-navbar-inverse-brand-color:                var(--ion-navbar-inverse-link-color);
--ion-navbar-inverse-brand-hover-color:          #fff;
--ion-navbar-inverse-brand-hover-bg:             transparent;

// Inverted navbar toggle
--ion-navbar-inverse-toggle-hover-bg:            #333;
--ion-navbar-inverse-toggle-icon-bar-bg:         #fff;
--ion-navbar-inverse-toggle-border-color:        #333;


//== Navs
//
//##

//=== Shared nav styles
--ion-nav-link-padding:                          10px 15px;
--ion-nav-link-hover-bg:                         var(--ion-gray-lighter);

--ion-nav-disabled-link-color:                   var(--ion-gray-light);
--ion-nav-disabled-link-hover-color:             var(--ion-gray-light);

//== Tabs
--ion-nav-tabs-border-color:                     #ddd;

--ion-nav-tabs-link-hover-border-color:          var(--ion-gray-lighter);

--ion-nav-tabs-active-link-hover-bg:             var(--ion-body-bg);
--ion-nav-tabs-active-link-hover-color:          var(--ion-gray);
--ion-nav-tabs-active-link-hover-border-color:   #ddd;

--ion-nav-tabs-justified-link-border-color:            #ddd;
--ion-nav-tabs-justified-active-link-border-color:     var(--ion-body-bg);

//== Pills
--ion-nav-pills-border-radius:                   var(--ion-border-radius-base);
--ion-nav-pills-active-link-hover-bg:            var(--ion-component-active-bg);
--ion-nav-pills-active-link-hover-color:         var(--ion-component-active-color);


//== Pagination
//
//##

--ion-pagination-color:                     var(--ion-link-color);
--ion-pagination-bg:                        #fff;
--ion-pagination-border:                    #ddd;

--ion-pagination-hover-color:               var(--ion-link-hover-color);
--ion-pagination-hover-bg:                  var(--ion-gray-lighter);
--ion-pagination-hover-border:              #ddd;

--ion-pagination-active-color:              #fff;
--ion-pagination-active-bg:                 var(--ion-brand-primary);
--ion-pagination-active-border:             var(--ion-brand-primary);

--ion-pagination-disabled-color:            var(--ion-gray-light);
--ion-pagination-disabled-bg:               #fff;
--ion-pagination-disabled-border:           #ddd;


//== Pager
//
//##

--ion-pager-bg:                             var(--ion-pagination-bg);
--ion-pager-border:                         var(--ion-pagination-border);
--ion-pager-border-radius:                  15px;

--ion-pager-hover-bg:                       var(--ion-pagination-hover-bg);

--ion-pager-active-bg:                      var(--ion-pagination-active-bg);
--ion-pager-active-color:                   var(--ion-pagination-active-color);

--ion-pager-disabled-color:                 var(--ion-pagination-disabled-color);


//== Jumbotron
//
//##

--ion-jumbotron-padding:              30px;
--ion-jumbotron-color:                inherit;
--ion-jumbotron-bg:                   var(--ion-gray-lighter);
--ion-jumbotron-heading-color:        inherit;
--ion-jumbotron-font-size:            ceil((var(--ion-font-size-base) * 1.5));
--ion-jumbotron-heading-font-size:    ceil((var(--ion-font-size-base) * 4.5));


//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

--ion-state-success-text:             #3c763d;
--ion-state-success-bg:               #dff0d8;
--ion-state-success-border:           darken(adjust-hue(var(--ion-state-success-bg), -10), 5%);

--ion-state-info-text:                #31708f;
--ion-state-info-bg:                  #d9edf7;
--ion-state-info-border:              darken(adjust-hue(var(--ion-state-info-bg), -10), 7%);

--ion-state-warning-text:             #8a6d3b;
--ion-state-warning-bg:               #fcf8e3;
--ion-state-warning-border:           darken(adjust-hue(var(--ion-state-warning-bg), -10), 5%);

--ion-state-danger-text:              #a94442;
--ion-state-danger-bg:                #f2dede;
--ion-state-danger-border:            darken(adjust-hue(var(--ion-state-danger-bg), -10), 5%);


//== Tooltips
//
//##

//** Tooltip max width
--ion-tooltip-max-width:           200px;
//** Tooltip text color
--ion-tooltip-color:               #fff;
//** Tooltip background color
--ion-tooltip-bg:                  #000;
--ion-tooltip-opacity:             .9;

//** Tooltip arrow width
--ion-tooltip-arrow-width:         5px;
//** Tooltip arrow color
--ion-tooltip-arrow-color:         var(--ion-tooltip-bg);


//== Popovers
//
//##

//** Popover body background color
--ion-popover-bg:                          #fff;
//** Popover maximum width
--ion-popover-max-width:                   276px;
//** Popover border color
--ion-popover-border-color:                rgba(0, 0, 0, .2);
//** Popover fallback border color
--ion-popover-fallback-border-color:       #ccc;

//** Popover title background color
--ion-popover-title-bg:                    darken(var(--ion-popover-bg), 3%);

//** Popover arrow width
--ion-popover-arrow-width:                 10px;
//** Popover arrow color
--ion-popover-arrow-color:                 var(--ion-popover-bg);

//** Popover outer arrow width
--ion-popover-arrow-outer-width:           (var(--ion-popover-arrow-width) + 1);
//** Popover outer arrow color
--ion-popover-arrow-outer-color:           fade_in(var(--ion-popover-border-color), 0.05);
//** Popover outer arrow fallback color
--ion-popover-arrow-outer-fallback-color:  darken(var(--ion-popover-fallback-border-color), 20%);


//== Labels
//
//##

//** Default label background color
--ion-label-default-bg:            var(--ion-gray-light);
//** Primary label background color
--ion-label-primary-bg:            var(--ion-brand-primary);
//** Success label background color
--ion-label-success-bg:            var(--ion-brand-success);
//** Info label background color
--ion-label-info-bg:               var(--ion-brand-info);
//** Warning label background color
--ion-label-warning-bg:            var(--ion-brand-warning);
//** Danger label background color
--ion-label-danger-bg:             var(--ion-brand-danger);

//** Default label text color
--ion-label-color:                 #fff;
//** Default text color of a linked label
--ion-label-link-hover-color:      #fff;


//== Modals
//
//##

//** Padding applied to the modal body
--ion-modal-inner-padding:         15px;

//** Padding applied to the modal title
--ion-modal-title-padding:         15px;
//** Modal title line-height
--ion-modal-title-line-height:     var(--ion-line-height-base);

//** Background color of modal content area
--ion-modal-content-bg:                             #fff;
//** Modal content border color
--ion-modal-content-border-color:                   rgba(0, 0, 0, .2);
//** Modal content border color **for IE8**
--ion-modal-content-fallback-border-color:          #999;

//** Modal backdrop background color
--ion-modal-backdrop-bg:           #000;
//** Modal backdrop opacity
--ion-modal-backdrop-opacity:      .5;
//** Modal header border color
--ion-modal-header-border-color:   #e5e5e5;
//** Modal footer border color
--ion-modal-footer-border-color:   var(--ion-modal-header-border-color);

--ion-modal-lg:                    900px;
--ion-modal-md:                    600px;
--ion-modal-sm:                    300px;


//== Alerts
//
//## Define alert colors, border radius, and padding.

--ion-alert-padding:               15px;
--ion-alert-border-radius:         var(--ion-border-radius-base);
--ion-alert-link-font-weight:      bold;

--ion-alert-success-bg:            var(--ion-state-success-bg);
--ion-alert-success-text:          var(--ion-state-success-text);
--ion-alert-success-border:        var(--ion-state-success-border);

--ion-alert-info-bg:               var(--ion-state-info-bg);
--ion-alert-info-text:             var(--ion-state-info-text);
--ion-alert-info-border:           var(--ion-state-info-border);

--ion-alert-warning-bg:            var(--ion-state-warning-bg);
--ion-alert-warning-text:          var(--ion-state-warning-text);
--ion-alert-warning-border:        var(--ion-state-warning-border);

--ion-alert-danger-bg:             var(--ion-state-danger-bg);
--ion-alert-danger-text:           var(--ion-state-danger-text);
--ion-alert-danger-border:         var(--ion-state-danger-border);


//== Progress bars
//
//##

//** Background color of the whole progress component
--ion-progress-bg:                 #f5f5f5;
//** Progress bar text color
--ion-progress-bar-color:          #fff;
//** Variable for setting rounded corners on progress bar.
--ion-progress-border-radius:      var(--ion-border-radius-base);

//** Default progress bar color
--ion-progress-bar-bg:             var(--ion-brand-primary);
//** Success progress bar color
--ion-progress-bar-success-bg:     var(--ion-brand-success);
//** Warning progress bar color
--ion-progress-bar-warning-bg:     var(--ion-brand-warning);
//** Danger progress bar color
--ion-progress-bar-danger-bg:      var(--ion-brand-danger);
//** Info progress bar color
--ion-progress-bar-info-bg:        var(--ion-brand-info);


//== List group
//
//##

//** Background color on `.list-group-item`
--ion-list-group-bg:                 #fff;
//** `.list-group-item` border color
--ion-list-group-border:             #ddd;
//** List group border radius
--ion-list-group-border-radius:      var(--ion-border-radius-base);

//** Background color of single list items on hover
--ion-list-group-hover-bg:           #f5f5f5;
//** Text color of active list items
--ion-list-group-active-color:       var(--ion-component-active-color);
//** Background color of active list items
--ion-list-group-active-bg:          var(--ion-component-active-bg);
//** Border color of active list elements
--ion-list-group-active-border:      var(--ion-list-group-active-bg);
//** Text color for content within active list items
--ion-list-group-active-text-color:  lighten(var(--ion-list-group-active-bg), 40%);

//** Text color of disabled list items
--ion-list-group-disabled-color:      var(--ion-gray-light);
//** Background color of disabled list items
--ion-list-group-disabled-bg:         var(--ion-gray-lighter);
//** Text color for content within disabled list items
--ion-list-group-disabled-text-color: var(--ion-list-group-disabled-color);

--ion-list-group-link-color:         #555;
--ion-list-group-link-hover-color:   var(--ion-list-group-link-color);
--ion-list-group-link-heading-color: #333;


//== Panels
//
//##

--ion-panel-bg:                    #fff;
--ion-panel-body-padding:          15px;
--ion-panel-heading-padding:       10px 15px;
--ion-panel-footer-padding:        var(--ion-panel-heading-padding);
--ion-panel-border-radius:         var(--ion-border-radius-base);

//** Border color for elements within panels
--ion-panel-inner-border:          #ddd;
--ion-panel-footer-bg:             #f5f5f5;

--ion-panel-default-text:          var(--ion-gray-dark);
--ion-panel-default-border:        #ddd;
--ion-panel-default-heading-bg:    #f5f5f5;

--ion-panel-primary-text:          #fff;
--ion-panel-primary-border:        var(--ion-brand-primary);
--ion-panel-primary-heading-bg:    var(--ion-brand-primary);

--ion-panel-success-text:          var(--ion-state-success-text);
--ion-panel-success-border:        var(--ion-state-success-border);
--ion-panel-success-heading-bg:    var(--ion-state-success-bg);

--ion-panel-info-text:             var(--ion-state-info-text);
--ion-panel-info-border:           var(--ion-state-info-border);
--ion-panel-info-heading-bg:       var(--ion-state-info-bg);

--ion-panel-warning-text:          var(--ion-state-warning-text);
--ion-panel-warning-border:        var(--ion-state-warning-borde);
--ion-panel-warning-heading-bg:    var(--ion-state-warning-bg);

--ion-panel-danger-text:           var(--ion-state-danger-text);
--ion-panel-danger-border:         var(--ion-state-danger-border);
--ion-panel-danger-heading-bg:     var(--ion-state-danger-bg);


//== Thumbnails
//
//##

//** Padding around the thumbnail image
--ion-thumbnail-padding:           4px;
//** Thumbnail background color
--ion-thumbnail-bg:                var(--ion-body-bg);
//** Thumbnail border color
--ion-thumbnail-border:            #ddd;
//** Thumbnail border radius
--ion-thumbnail-border-radius:     var(--ion-border-radius-base);

//** Custom text color for thumbnail captions
--ion-thumbnail-caption-color:     var(--ion-text-color);
//** Padding around the thumbnail caption
--ion-thumbnail-caption-padding:   9px;


//== Wells
//
//##

--ion-well-bg:                     #f5f5f5;
--ion-well-border:                 darken(var(--ion-well-bg), 7%);


//== Badges
//
//##

--ion-badge-color:                 #fff;
//** Linked badge text color on hover
--ion-badge-link-hover-color:      #fff;
--ion-badge-bg:                    var(--ion-gray-light);

//** Badge text color in active nav link
--ion-badge-active-color:          var(--ion-link-color);
//** Badge background color in active nav link
--ion-badge-active-bg:             #fff;

--ion-badge-font-weight:           bold;
--ion-badge-line-height:           1;
--ion-badge-border-radius:         10px;


//== Breadcrumbs
//
//##

--ion-breadcrumb-padding-vertical:   8px;
--ion-breadcrumb-padding-horizontal: 15px;
//** Breadcrumb background color
--ion-breadcrumb-bg:                 #f5f5f5;
//** Breadcrumb text color
--ion-breadcrumb-color:              #ccc;
//** Text color of current page in the breadcrumb
--ion-breadcrumb-active-color:       var(--ion-gray-light);
//** Textual separator for between breadcrumb elements
--ion-breadcrumb-separator:          "/";


//== Carousel
//
//##

--ion-carousel-text-shadow:                        0 1px 2px rgba(0, 0, 0, .6);

--ion-carousel-control-color:                      #fff;
--ion-carousel-control-width:                      15%;
--ion-carousel-control-opacity:                    .5;
--ion-carousel-control-font-size:                  20px;

--ion-carousel-indicator-active-bg:                #fff;
--ion-carousel-indicator-border-color:             #fff;

--ion-carousel-caption-color:                      #fff;


//== Close
//
//##

--ion-close-font-weight:           bold;
--ion-close-color:                 #000;
--ion-close-text-shadow:           0 1px 0 #fff;


//== Code
//
//##

--ion-code-color:                  #c7254e;
--ion-code-bg:                     #f9f2f4;

--ion-kbd-color:                   #fff;
--ion-kbd-bg:                      #333;

--ion-pre-bg:                      #f5f5f5;
--ion-pre-color:                   var(--ion-gray-dark);
--ion-pre-border-color:            #ccc;
--ion-pre-scrollable-max-height:   340px;


//== Type
//
//##

//** Horizontal offset for forms and lists.
--ion-component-offset-horizontal: 180px;
//** Text muted color
--ion-text-muted:                  var(--ion-gray-light);
//** Abbreviations and acronyms border color
--ion-abbr-border-color:           var(--ion-gray-light);
//** Headings small color
--ion-headings-small-color:        var(--ion-gray-light);
//** Blockquote small color
--ion-blockquote-small-color:      var(--ion-gray-light);
//** Blockquote font size
--ion-blockquote-font-size:        (var(--ion-font-size-base) * 1.25);
//** Blockquote border color
--ion-blockquote-border-color:     var(--ion-gray-lighter);
//** Page header border color
--ion-page-header-border-color:    var(--ion-gray-lighter);
//** Width of horizontal description list titles
--ion-dl-horizontal-offset:        var(--ion-component-offset-horizontal);
//** Point at which .dl-horizontal becomes horizontal
--ion-dl-horizontal-breakpoint:    var(--ion-grid-float-breakpoint);
//** Horizontal line color.
--ion-hr-border:                   var(--ion-gray-lighter);

}