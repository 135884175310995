// combine with .form-actions--fixed
.form--fixed {
	@media screen and (max-width: var(--ion-grid-float-breakpoint-max)) {
		padding-bottom: 7em;
	}
}

.form-col {
	width: auto;
}

.form-control {
	box-shadow:(none);

	&[readonly] {
		border: none;
	}
}

.form-control:not([readonly]):not(.disabled):focus {
	color: lighten(lighten(#000, 60%),20%);
}

.form-header {
	margin: 0 0 1em;
	font-size: 0.9em;
	font-weight: 600;
	text-transform: uppercase;

	@media screen and (min-width: var(--ion-grid-float-breakpoint)) {
		color: lighten(#4D4D4D, 35%);
		line-height: 1.5;
	}
}

.control-label {
	font-size: 0.95em;
	font-weight: 600;
	margin-bottom: 2px;
}

.control-label--block {
	display: block;
}

.input-group--inline {
	@include flexbox();
	@include align-items(center);

	.input--inline {
		@include flex(1);
	}

	.input-text--inline {
		font-size: 1.1em;
		padding-left: 10px;
	}
}

.form-group--optional > .control-label:after {
    content: attr(data-optional);
    display: inline-block;
    margin-left: 5px;
    font-size: 10px;
    text-transform: uppercase;
    color: var(--ion-gray-light);
	background: var(--ion-gray-lightest);
	padding: 0 5px;
	border-radius: 3px;
	font-weight: 600;
}

.form-section {
	position: relative;
	margin-top: 0;
	padding: 20px 0;

	+ .form-section {
		border-top: 1px solid var(--ion-separator-color);
	}
}

// horizontal form sections (form header placed next to form-controls)
.form-section--inline {

	@media screen and (min-width: var(--ion-grid-float-breakpoint)) {

		> .row {
			margin-left: 20%;
		}

		.form-header {
			position: absolute;
			top: 50%;
			max-width: 18%;
			margin: 0;
			transform: translateY(-50%);
		}
	}


	// trying to remove bottom padding on the last form-group in section
	// to center the section header vertically
	> .row:last-child .form-group:last-child {
		margin-bottom: 0;
	}
}

.form-section--separate {
	@extend .mobile-full-width;
	border-bottom: 1px solid var(--ion-separator-color);

	+ .form-section {
		border: 0;
	}
}

.form-group--compact {
	margin-bottom: 5px;
}

// help texts - can be guiding texts or error messages
.help-block {
	font-size: 0.9em;
}

.input--inline {
	display: inline-block;
	width: auto;
}

.control-label-hard {
	font-size: 10px;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #999;
	background: #f4f4f4;
	padding: 1px 15px;
	border-radius: 2px;
	font-weight: 600;
	position: relative;
	width: max-content;
	margin: 4px 0 2px 0;
}


// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;

  // These are used on elements with <label> descendants
  &.disabled,
  fieldset[disabled] & {
    label {
      cursor: var(--ion-cursor-disabled);
    }
  }

  label {
    min-height: var(--ion-line-height-computed); // Ensure the input doesn't jump when there is no text
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
  }
}
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; // Move up sibling radios or checkboxes for tighter spacing
}

// Radios and checkboxes on same line
.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;

  // These are used directly on <label>s
  &.disabled,
  fieldset[disabled] & {
    cursor: var(--ion-cursor-disabled);
  }
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; // space out consecutive inline controls
}

