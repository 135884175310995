///////////////////////////////////////////////////////////////
//
//  checkbox/switches
//
//
//  Based on traditional checkboxes which are hidden and
//  replaced by nifty animated switches.
//
//  Elements needed:
//  .cb-switch
//    - label.cb-switch__label
//    - input[type="checkbox"]
//    - .cb-switch__icon
//
//  Note: markup is not identical to bootstraps (label doesn't surround the input)
//
//
///////////////////////////////////////////////////////////////


// proportions
:root {
    --ion-switch-width: 55px;
    --ion-switch-height: 30px;
    --ion-switch-padding: 3px;
    --ion-switch-knob-size: var(--ion-switch-height) - (var(--ion-switch-padding) * 2);
    
    --ion-checkbox-size: 20px;
    
    --ion-switch-offset: -3px;

}

///////////////////////////////////////////////////////////////



///////////////////////////////////////

// general styles

///////////////////////////////////////

.cb-switch {
    @extend .checkbox !optional;
}

// make sure the real checkbox is on top of the other elements.
.cb-switch input[type="checkbox"] {
    opacity: 0;
    z-index: 10;
    margin: 0;
    cursor: pointer;
}

.cb-switch__label:hover {
    cursor: pointer;
}

.cb-switch__label::after {
    content: var(--ion-icon-ok);
}

// cant do this inside the mediaqueries - needs a loving hand
.cb-switch__icon {
    display: none;

    &::before {
        content: var(--ion-icon-ok);
        font-family: "icons";
    }

    &::after {
        content: var(--ion-icon-close);
        font-family: "icons";
    }
}





///////////////////////////////////////

//  disabled state

///////////////////////////////////////

.cb-switch input[type="checkbox"][disabled]:hover {
    cursor: var(--ion-cursor-disabled);
}

.cb-switch input[type="checkbox"][disabled] + .cb-switch__label:hover {
    cursor: default;
}


///////////////////////////////////////

//  on mobile show switches

///////////////////////////////////////

@media screen and (max-width: var(--ion-screen-xs-max)) {

    .cb-switch {
        display: block;
        min-height: var(--ion-switch-height);
        margin: 0;

        // general styles
        input[type="checkbox"],
        .cb-switch__label::before,
        .cb-switch__icon {
            position: absolute;
            top: var(--ion-switch-offset);
            right: 0;
            width: var(--ion-switch-width);
            height: var(--ion-switch-height);
            border-radius: var(--ion-switch-height / 2);
        }

        // text label next to the switch
        .cb-switch__label {
            display: block;
            position: relative;
            max-width: none;
            min-height: var(--ion-switch-height);
            padding: 0 (--ion-switch-width + 15px) 0 0;
            @include user-select(none);
            transition:(color 0.2s);
            @include translate3d(0,0,0);
            @include backface-visibility(hidden);
        }

        .cb-switch__label::before,
        .cb-switch__label::after {
            content: "";
            position: absolute;
        }

        // switch background
        .cb-switch__label::before {
            background: #fff;
            border: 1px solid var(--ion-color-3);
            will-change: transform;
            transition:(all 0.5s);
        }

        // switch knob
        .cb-switch__label::after {
            display: block;
            top: var(--ion-switch-padding + var(--ion-switch-offset));
            right: 0;
            width: var(--ion-switch-knob-size);
            height: var(--ion-switch-knob-size);
            @include translate(-(var(--ion-switch-width) - var(--ion-switch-knob-size)) + var(--ion-switch-padding), 0);
            // @include translate(-((var(--ion-switch-width) - var(--ion-switch-knob-size)) + var(--ion-switch-padding), 0));
            background: #fff;
            border-radius: 50%;
            // box-shadow:(0 0 0 1px rgba(--ion-color-3, 0.4), 0 2px 4px 0 rgba(0,0,0,0.30));
            transition:(all 0.2s);
        }

        // on/off icon container
        .cb-switch__icon {
            display: block;

            &::before,
            &::after {
                position: absolute;
                top: 50%;
                margin: 0 !important;
                transition:(transform 0.3s, opacity 0.3s);
                font-size: 0.6em;
            }

            // on icon
            &::before {
                opacity: 0;
                left: 15%;
                @include translate(75%,-45%);
                color: #fff;
            }

            // off icon
            &::after {
                right: 15%;
                @include translate(0,-45%);
                color: var(--ion-color-2);
            }
        }


        ///////////////////////////////////////

        //  active state

        ///////////////////////////////////////

        input[type="checkbox"]:checked + .cb-switch__label {

            // switch background
            &::before {
                background: var(--ion-brand-primary);
                border-color: var(--ion-brand-primary);
            }

            // switch knob
            &::after {
                @include translate(---ion-switch-padding,0);
                box-shadow:(0 0 0 1px #fff, 0 2px 4px 0 rgba(0,0,0,0.30));
            }

            // icon animations
            .cb-switch__icon {

                // on icon
                &::before {
                    opacity: 1;
                    @include translate(0,-45%);
                }

                // off icon
                &::after {
                    opacity: 0;
                    @include translate(-75% ,-45%);
                }
            }
        }
    }


    ///////////////////////////////////////

    //  deactivated state

    ///////////////////////////////////////

    .cb-switch input[type="checkbox"][disabled] + .cb-switch__label,
    .cb-switch input[type="checkbox"][disabled] + .cb-switch__label::after {
        opacity: 0.7;
    }

    ///////////////////////////////////////

    //  indeterminate state

    ///////////////////////////////////////



    // hide icons
    .cb-switch input[type="checkbox"]:indeterminate + .cb-switch__label {

        // switch background
        &::before {
            background-color: var(--ion-brand-primary);
            border-color: var(--ion-brand-primary);
        }

        // knob
        &::after {
            @include translate(-((--ion-switch-width/2) - (--ion-switch-knob-size/2)), 0);
        }

        .cb-switch__icon::before,
        .cb-switch__icon::after {
            display: none;
        }
    }
}



///////////////////////////////////////

//  on desktop show checkboxes

///////////////////////////////////////

@media screen and (min-width: var(--ion-screen-sm-min)) {

    .cb-switch {
        margin: 0;
        min-height: var(--ion-checkbox-size);
    }

    .cb-switch + .cb-switch {
        margin-top: 0;
    }

    .cb-switch .cb-switch__label {
        position: relative;
        max-width: none;
        min-height: var(--ion-checkbox-size);
        padding-left: var(--ion-checkbox-size + 15);
        transition:(color 0.2s);
        @include user-select(none);
        @include backface-visibility(hidden);
        @include translate3d(0,0,0);
    }

    .cb-switch input[type="checkbox"],
    .cb-switch__label::before,
    .cb-switch__label::after {
        position: absolute;
        display: inline-block;
        width: var(--ion-checkbox-size);
        height: var(--ion-checkbox-size);
        left: 0;
        top: var(--ion-checkbox-offset);
        border-radius: 2px;
    }

    .cb-switch__label::before,
    .cb-switch__label::after {
        text-align: center;
        transition:(all 0.2s);
    }

    // checkbox background
    .cb-switch__label::before {
        content: "";
        background: #fff;
        border: 1px solid var(--ion-input-border);
        will-change: transform;
    }

    // check icon
    .cb-switch__label::after {
        font-family: "icons";
        opacity: 0;
        line-height: var(--ion-checkbox-size);
        font-size: 0.75em;
        color: white;
        @include translate(0,-25%);
        transition:-delay(0s);
    }

    ///////////////////////////////////////

    //  checked state

    ///////////////////////////////////////

    .cb-switch input[type="checkbox"]:checked + .cb-switch__label,
    .cb-switch input[type="checkbox"]:indeterminate + .cb-switch__label {
        color: var(--ion-text-color);

        // checkbox background
        &::before {
            background: var(--ion-brand-primary);
            border-color: var(--ion-brand-primary);
            transition:-delay(0s);
        }

        &::after {
            opacity: 1;
            transform: translateY(0);
            transition:-delay(0.1s);
        }
    }

    ///////////////////////////////////////

    //  deactivated state

    ///////////////////////////////////////

    .cb-switch input[type="checkbox"][disabled] + .cb-switch__label,
    .cb-switch input[type="checkbox"][disabled] + .cb-switch__label::before {
        opacity: 0.7;
    }

    // //  :focus state (when tabbing)
    // .cb-switch input[type="checkbox"]:focus + .cb-switch__label::before {
    //     // box-shadow:(0 0 8px var(--ion-input-border-focus-rgba);
    //     background-color: var(--ion-gray-lighter;
    // }


    ///////////////////////////////////////

    //  indeterminate state

    // Use the following in the console to test this state

    // let checkbox = document.getElementById("checkbox-all");
    // checkbox.indeterminate = true;

    ///////////////////////////////////////

    .cb-switch input[type="checkbox"]:indeterminate + .cb-switch__label::after {
        content: "O";
    }

}
