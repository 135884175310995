.icon-size--30 {
  font-size: 30px;
}

.icon-size--40 {
  font-size: 40px;
}

.icon-size--50 {
  font-size: 50px;
}

.icon-size--100 {
  font-size: 100px;
}

.ion-item-label-floating-icon {
  margin-top: 20px;
}

.img-logo-margin--20 {
  margin-top: 20px;
  margin-bottom: 20px;
}