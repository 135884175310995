///////////////////////////////////////////////////////////////

// Custom radiobuttons

///////////////////////////////////////////////////////////////
:root {
    --ion-radio-size: 20px;
    --ion-radio-inner-size: 8px;
    --ion-radio-offset: 3px; // for vertically aligning the radio to the label
}

///////////////////////////////////////////////////////////////


.rb {
    @extend .radio;
}

// make sure the real radiobutton is on top of the other elements.
.rb input[type="radio"] {
    opacity: 0;
    z-index: 10;
    margin: 0;
    cursor: pointer;
}

.rb__label:hover {
    cursor: pointer;
}


///////////////////////////////////////

.rb {
    margin: 0;
    min-height: var(--ion-radio-size);
    min-width: var(--ion-radio-size);
}

.rb + .rb {
    margin-top: 0;
}

.rb .rb__label {
    position: relative;
    max-width: none;
    min-height: var(--ion-radio-size);
    padding-left: var(--ion-radio-size + 15);
    transition:(color 0.2s);
    @include user-select(none);
    @include backface-visibility(hidden);
    @include translate3d(0,0,0);
}

.rb .rb__label--hidden {
    padding-left: 0;
}

.rb input[type="radio"],
.rb__label::before,
.rb__label::after {
    position: absolute;
    display: inline-block;
    width: var(--ion-radio-size);
    height: var(--ion-radio-size);
    left: 0;
    top: var(--ion-radio-offset);
    border-radius: 50%;
}

.rb__label::before,
.rb__label::after {
    content: "";
    text-align: center;
}

// radio background
.rb__label::before {
    background: #fff;
    border: 1px solid var(--ion-input-border);
}

// center circle
.rb__label::after {
    opacity: 0;
    top: var(--ion-radio-size) / 2 + var(--ion-radio-offset);
    left: var(--ion-radio-size / 2);
    height: var(--ion-radio-inner-size);
    width: var(--ion-radio-inner-size);
    background: var(--ion-brand-primary);
    @include transform(scale(0.5) translate(-50%, -50%));
    @include transform-origin(top left);
    transition:(transform 0.15s);
}

///////////////////////////////////////

//  checked state

///////////////////////////////////////

.rb input[type="radio"]:checked + .rb__label {
    color: var(--ion-text-color);

    // show inner circle
    &::after {
        opacity: 1;
        @include transform(scale(1) translate(-50%, -50%));
    }
}


// alternative version for lists and active states

.rb--alt .rb__label {
    padding-left: 0;
}

.rb--alt input[type="radio"]:checked + .rb__label {


    &::before {
        border-color: #fff;
    }
}


///////////////////////////////////////

//  deactivated state

///////////////////////////////////////

.rb input[type="radio"][disabled] + .rb__label,
.rb input[type="radio"][disabled] + .rb__label::before {
    opacity: 0.7;
}
