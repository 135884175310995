//
// Tooltips
// --------------------------------------------------


// Base class
.tooltip {
  position: absolute;
  z-index: var(--ion-zindex-tooltip);
  display: block;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text;
  font-size: var(--ion-font-size-small);

  @include opacity(0);

  &.in { @include opacity(var(--ion-tooltip-opacity)); }
  &.top {
    padding: var(--ion-tooltip-arrow-width 0);
    margin-top: -3px;
  }
  &.right {
    padding: 0 var(--ion-tooltip-arrow-width);
    margin-left: 3px;
  }
  &.bottom {
    padding: var(--ion-tooltip-arrow-width 0);
    margin-top: 3px;
  }
  &.left {
    padding: 0 var(--ion-tooltip-arrow-width);
    margin-left: -3px;
  }

  // Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
  &.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -var(--ion-tooltip-arrow-width);
    border-width: var(--ion-tooltip-arrow-width) var(--ion-tooltip-arrow-width 0);
    border-top-color: var(--ion-tooltip-arrow-color);
  }
  &.top-left .tooltip-arrow {
    right: var(--ion-tooltip-arrow-width);
    bottom: 0;
    margin-bottom: -var(--ion-tooltip-arrow-width);
    border-width: var(--ion-tooltip-arrow-width) var(--ion-tooltip-arrow-width) 0;
    border-top-color: var(--ion-tooltip-arrow-color);
  }
  &.top-right .tooltip-arrow {
    bottom: 0;
    left: var(--ion-tooltip-arrow-width);
    margin-bottom: -var(--ion-tooltip-arrow-width);
    border-width: var(--ion-tooltip-arrow-width) var(--ion-tooltip-arrow-width 0);
    border-top-color: var(--ion-tooltip-arrow-color);
  }
  &.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -var(--ion-tooltip-arrow-width);
    border-width: var(--ion-tooltip-arrow-width) var(--ion-tooltip-arrow-width) var(--ion-tooltip-arrow-width 0);
    border-right-color: var(--ion-tooltip-arrow-color);
  }
  &.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -var(--ion-tooltip-arrow-width);
    border-width: var(--ion-tooltip-arrow-width) 0 var(--ion-tooltip-arrow-width) var(--ion-tooltip-arrow-width);
    border-left-color: var(--ion-tooltip-arrow-color);
  }
  &.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -var(--ion-tooltip-arrow-width);
    border-width: 0 var(--ion-tooltip-arrow-width) var(--ion-tooltip-arrow-width);
    border-bottom-color: var(--ion-tooltip-arrow-color);
  }
  &.bottom-left .tooltip-arrow {
    top: 0;
    right: var(--ion-tooltip-arrow-width);
    margin-top: -var(--ion-tooltip-arrow-width);
    border-width: 0 var(--ion-tooltip-arrow-width) var(--ion-tooltip-arrow-width);
    border-bottom-color: var(--ion-tooltip-arrow-color);
  }
  &.bottom-right .tooltip-arrow {
    top: 0;
    left: var(--ion-tooltip-arrow-width);
    margin-top: -var(--ion-tooltip-arrow-width);
    border-width: 0 var(--ion-tooltip-arrow-width) var(--ion-tooltip-arrow-width);
    border-bottom-color: var(--ion-tooltip-arrow-color);
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: var(--ion-tooltip-max-width);
  padding: 3px 8px;
  color: var(--ion-tooltip-color);
  text-align: center;
  background-color: var(--ion-tooltip-bg);
  border-radius: var(--ion-border-radius-base);
}

// Arrows
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
