@charset "UTF-8";

@font-face {
  font-family: 'icons';
  src:  url('/ionic-user-app/src/assets/fonts/icomoon/icomoon.eot?fro52u');
  src:  url('../assets/fonts/icomoon/icomoon.eot?fro52u#iefix') format('embedded-opentype'),
        url('../assets/fonts/icomoon/icomoon.woff2?fro52u') format('woff2'),
        url('../assets/fonts/icomoon/icomoon.ttf?fro52u') format('truetype'),
        url('../assets/fonts/icomoon/icomoon.woff?fro52u') format('woff'),
        url('../assets/fonts/icomoon/icomoon.svg?fro52u#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin makeIcon($arg, $val) {
  .ai-#{$arg}:before ,
  .ion-ios-ai-#{$arg}:before ,
  .ion-ios-ai-#{$arg}-outline:before ,
  .ion-md-ai-#{$arg}:before ,
  .ion-md-ai-#{$arg}-outline:before {
  content: $val;
  font-size: 26px;
  }
}


.icon,
[class^="icon-"]:before,
[class*=" icon-"]:before {
  display: inline-block;
  font-family: "icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // vertical-align: -15%;
  font-size: 0.9em;
}

.icon--sm {
  font-size: 0.8em;
}

.icon--lg,
.icon-lg {
  font-size: 1.3em;
}

.icon--xl {
    font-size: 1.8em;
}

.icon--center::before {
    vertical-align: middle;
}

.icon--spacing-right {
    margin-right: 0.3em;
}

.icon--spacing-left {
    margin-left: 0.3em;
}

.icon--active {
    // color: --ion-brand-primary;
}

// add notification icon on top of a icon
.icon--notify {
    position: relative;
    padding: 0 1px; // fix icon overflowing the container and cutting off a pixel

    &::after {
        content: "";
        position: absolute;
        display: block;
        top: -2px;
        right: -2px;
        width: 6px;
        height: 6px;
        // background: --ion-brand-red;
        border-radius: 50%;
    }
}

///////////////////////////////////////////////////////////////

// Only replace below this line when updating the font - please

///////////////////////////////////////////////////////////////

.icon-crop:before {
  content: "\e99c";
}
.icon-clips:before {
  content: "\e99b";
}
.icon-house:before {
  content: "\e9b1";
}
.icon-doc_blank:before {
  content: "\e99a";
}
.icon-onedrive:before {
  content: "\e998";
}
.icon-sharepoint:before {
  content: "\e999";
}
.icon-capture-wf:before {
  content: "\e997";
}
.icon-capture:before {
  content: "\e996";
}
.icon-eye:before {
  content: "\e995";
}
.icon-printer-software:before {
  content: "\e994";
}
.icon-user-me:before {
  content: "\e991";
}
.icon-wip:before {
  content: "\e993";
}
.icon-alert:before {
  content: "\e992";
}
.icon-deploy:before {
  content: "\e990";
}
.icon-gateway-11:before {
  content: "\e98b";
}
.icon-gateway-9:before {
  content: "\e98d";
}
.icon-gateway-5:before {
  content: "\e98e";
}
.icon-steps-arrow-2:before {
  content: "\e98f";
}
.icon-steps-arrow:before {
  content: "\e98c";
}
.icon-universal:before {
  content: "\e98a";
}
.icon-process:before {
  content: "\e989";
}
.icon-ip-address:before {
  content: "\e988";
}
.icon-hourglas:before {
  content: "\e987";
}
.icon-lock-light:before {
  content: "\e986";
}
.icon-access-card-2:before {
  content: "\e985";
}
.icon-access-card:before {
  content: "\e984";
}
.icon-key-card2ai:before {
  content: "\e983";
}
.icon-home-print:before {
  content: "\e982";
}
.icon-key-card:before {
  content: "\e981";
}
.icon-flash-light:before {
  content: "\e980";
}
.icon-site-admin-2:before {
  content: "\e97f";
}
.icon-site-admin:before {
  content: "\e97e";
}
.icon-server:before {
  content: "\e97d";
}
.icon-install-auto:before {
  content: "\e976";
}
.icon-level-up:before {
  content: "\e977";
}
.icon-pin-plus:before {
  content: "\e978";
}
.icon-folder:before {
  content: "\e979";
}
.icon-folder-plus:before {
  content: "\e97a";
}
.icon-desktop:before {
  content: "\e97b";
}
.icon-delete-auto:before {
  content: "\e97c";
}
.icon-bug:before {
  content: "\e975";
}
.icon-db-extract:before {
  content: "\e974";
}
.icon-system:before {
  content: "\e96f";
}
.icon-db-exract:before {
  content: "\e971";
}
.icon-via-cloud:before {
  content: "\e972";
}
.icon-broken-cloud:before {
  content: "\e973";
}

.icon-proxy-tag:before {
  content: "\e96b";
}
.icon-qr-code-2:before {
  content: "\e96c";
}
.icon-beacon:before {
  content: "\e96d";
}
.icon-nfc-chip:before {
  content: "\e96e";
}
.icon-qr-code:before {
  content: "\e970";
}
.icon-qrcode:before {
  content: "\f029";
}
.icon-refresh:before {
  content: "\e915";
}
.icon-clock:before {
  content: "\e92f";
}
.icon-bluetooth:before {
  content: "\e969";
}
.icon-nfc:before {
  content: "\e96a";
  font-size: 117.5%;
}
.icon-info:before {
  content: "\e968";
  font-size: 105%;  
}
.icon-mono:before {
  content: "\e937";
}
.icon-arrow-next:before {
  content: "\e933";
  font-size: 80%;
}
.icon-computer:before {
  content: "\e92c";
  font-size: 80%;
}
.icon-home:before {
  content: "\e907";
}
.icon-docs:before {
  content: "\e900";
}
.icon-ban1:before {
  content: "\e908";
}
.icon-freedom:before {
  content: "\e909";
  font-size: 115%;
}
.icon-wrench:before {
  content: "\e90a";
}
.icon-user:before {
  content: "\e90b";
  font-size: 100%;
}
.icon-upload:before {
  content: "\e90c";
}
.icon-support:before {
  content: "\e90d";
  font-size: 100%;
}
.icon-subscription:before {
  content: "\e90e";
}
.icon-sign-out:before {
  content: "\e90f";
}
.icon-sign-in:before {
  content: "\e910";
}
.icon-settings:before {
  content: "\e911";
}
.icon-secure-print-sm:before {
  content: "\e912";
}
.icon-secure-print:before {
  content: "\e913";
}
.icon-search:before {
  content: "\e914";
}
.icon-print-solid:before {
  content: "\e916";
}
.icon-print:before {
  content: "\e917";
}
.icon-pin:before {
  content: "\e918";
}
.icon-ok:before {
  content: "\e919";
}
.icon-network:before {
  content: "\e91a";
}
.icon-modify:before {
  content: "\e91b";
}
.icon-minus:before {
  content: "\e91c";
}
.icon-menu-toggle:before {
  content: "\e91d";
}
.icon-language:before {
  content: "\e91e";
}
.icon-key:before {
  content: "\e91f";
}
.icon-invoice:before {
  content: "\e920";
}
.icon-history:before {
  content: "\e921";
}
.icon-help:before {
  content: "\e922";
}
.icon-group:before {
  content: "\e923";
}
.icon-favorite:before {
  content: "\e924";
}
.icon-favorite-solid:before {
  content: "\e925";
}
.icon-eco-sm:before {
  content: "\e926";
}
.icon-eco:before {
  content: "\e927";
}
.icon-download:before {
  content: "\e928";
}
.icon-delete:before {
  content: "\e929";
}
.icon-customize:before {
  content: "\e92a";
}
.icon-credit-card:before {
  content: "\e92b";
}
.icon-close:before {
  content: "\e92d";
}
.icon-clock-solid:before {
  content: "\e92e";
}
.icon-bulb:before {
  content: "\e930";
}
.icon-check:before {
  content: "\e931";
}
.icon-article:before {
  content: "\e932";
}
.icon-add:before {
  content: "\e934";
}
.icon-flash:before {
  content: "\e936";
}
.icon-flash-solid:before {
  content: "\e935";
}
.icon-duplex:before {
  content: "\e938";
}
.icon-redirect:before {
  content: "\e939";
}
.icon-paper-tray-2:before {
  content: "\e93a";
}
.icon-loud-speaker:before {
  content: "\e93b";
}
.icon-loud-speaker-solid:before {
  content: "\e93c";
}
.icon-filter-useful:before {
  content: "\e93d";
}
.icon-convert:before {
  content: "\e93e";
}
.icon-activate:before {
  content: "\e93f";
}
.icon-connection:before {
  content: "\e940";
}
.icon-tenant:before {
  content: "\e941";
}
.icon-sigma:before {
  content: "\e942";
}
.icon-partner:before {
  content: "\e943";
}
.icon-mobile-plain:before {
  content: "\e944";
}
.icon-fixed-price:before {
  content: "\e945";
}
.icon-education:before {
  content: "\e946";
}
.icon-double-angle-left:before {
  content: "\e947";
}
.icon-double-angle-right:before {
  content: "\e948";
}
.icon-business:before {
  content: "\e949";
}
.icon-analytics:before {
  content: "\e94a";
}
.icon-add-user:before {
  content: "\e94b";
}
.icon-add-tenant:before {
  content: "\e94c";
}
.icon-add-partner:before {
  content: "\e94d";
}
.icon-update-queue:before {
  content: "\e94e";
}
.icon-update-computers:before {
  content: "\e94f";
}
.icon-synchronize:before {
  content: "\e950";
}
.icon-question-1:before {
  content: "\e951";
}
.icon-pending-tasks:before {
  content: "\e952";
}
.icon-legal-docs:before {
  content: "\e953";
}
.icon-distribute-queue:before {
  content: "\e954";
}
.icon-distribute-driver:before {
  content: "\e955";
}
.icon-correct-network:before {
  content: "\e956";
}
.icon-add-queue-to-computers:before {
  content: "\e957";
}
.icon-add-queue-to-computer:before {
  content: "\e958";
}
.icon-px-logo:before {
  content: "\e959";
}
.icon-logo-menu-mono:before {
  content: "\e959";
}
.icon-printix:before {
  content: "\e959";
}
.icon-gear:before {
  content: "\e95a";
}
.icon-dashboard:before {
  content: "\e95b";
}
.icon-azure:before {
  content: "\e95c";
}
.icon-automated:before {
  content: "\e95d";
}
.icon-cloud:before {
  content: "\e95e";
}
.icon-sort-up:before {
  content: "\e95f";
}
.icon-sort-down:before {
  content: "\e960";
}
.icon-printer:before {
  content: "\e961";
}
.icon-overflow:before {
  content: "\e962";
}
.icon-overflow-vert:before {
  content: "\e963";
}
.icon-lock:before {
  content: "\e964";
}
.icon-lock-open:before {
  content: "\e965";
}
.icon-drivers:before {
  content: "\e966";
}
.icon-win:before {
  content: "\e901";
}
.icon-diagnostics:before {
  content: "\e905";
}
.icon-diagnostics-sm:before {
  content: "\e967";
}
.icon-mac:before {
  content: "\e906";
}
.icon-envelope-o:before {
  content: "\f003";
}
.icon-ban:before {
  content: "\f05e";
}
.icon-files-o:before {
  content: "\f0c5";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-warning:before {
  content: "\e902";
}
.icon-left-open:before {
  content: "\e903";
}
.icon-right-open:before {
  content: "\e904";
}
