///////////////////////////////////////////////////////////////

// Custom checkboxes

///////////////////////////////////////////////////////////////

@import "variables";
@import "./mixins";
@import "./forms";

:root {
    --ion-checkbox-size: 20px;
    --ion-checkbox-offset: 3px; // for vertically aligning the radio to the label
}


///////////////////////////////////////////////////////////////

.cb {
    @extend .checkbox;
}

// make sure the real checkbox is on top of the other elements.
.cb input[type="checkbox"] {
    opacity: 0;
    z-index: 10;
    margin: 0;
    cursor: pointer;
}

.cb__label:hover {
    cursor: pointer;
}


///////////////////////////////////////

.cb {
    margin: 0;
    height: var(--ion-checkbox-size);
    min-width: var(--ion-checkbox-size);
}

.cb + .cb {
    margin-top: 0;
}

.cb .cb__label {
    position: relative;
    max-width: none;
    min-height: var(--ion-checkbox-size);
    padding-left: var(--ion-checkbox-size + 15);
    transition:(color 0.2s);
    @include user-select(none);
    @include backface-visibility(hidden);
    @include translate3d(0,0,0);
}

// use for checkboxes in list with no text-label (needs a bit of finetuning)
.cb .cb__label--hidden {
    padding-left: 0;
}

.cb input[type="checkbox"],
.cb__label::before,
.cb__label::after {
    position: absolute;
    display: inline-block;
    width: var(--ion-checkbox-size);
    height: var(--ion-checkbox-size);
    left: 0;
    top: var(--ion-checkbox-offset);
    border-radius: 2px;
}

.cb__label::before,
.cb__label::after {
    content: "";
    text-align: center;
    transition:(all 0.2s);
}

// checkbox background
.cb__label::before {
    background: #fff;
    border: 1px solid var(--ion-input-border);
    will-change: transform;
}

// check icon
.cb__label::after {
    content: var(--ion-icon-ok);
    font-family: "icons";
    opacity: 0;
    line-height: var(--ion-checkbox-size);
    font-size: 0.75em;
    color: white;
    @include translate(0,-25%);
    transition:-delay(0s);
}

///////////////////////////////////////

//  checked state

///////////////////////////////////////

.cb input[type="checkbox"]:checked + .cb__label,
.cb input[type="checkbox"]:indeterminate + .cb__label {
    color: var(--ion-text-color);

    // checkbox background
    &::before {
        background: var(--ion-brand-primary);
        border-color: #fff;
        transition:-delay(0s);
    }

    &::after {
        opacity: 1;
        transform: translateY(0);
        transition:-delay(0.1s);
    }
}

// alternative version for lists and active states

.cb--alt .cb__label::after {
    color: var(--ion-brand-primary);
}

.cb--alt input[type="checkbox"]:checked + .cb__label,
.cb--alt input[type="checkbox"]:indeterminate + .cb__label {

    // checkbox background
    &::before {
        background: #fff;
        border-color: #fff;
    }
}



///////////////////////////////////////

//  deactivated state

///////////////////////////////////////

.cb input[type="checkbox"][disabled] + .cb__label,
.cb input[type="checkbox"][disabled] + .cb__label::before {
    opacity: 0.7;
}

// //  :focus state (when tabbing)
// .cb input[type="checkbox"]:focus + .cb__label::before {
//     background-color: var(--ion-gray-lighter;
// }

///////////////////////////////////////

//  indeterminate state

// Use the following in the console to test this state

// let checkbox = document.getElementById("checkbox-all");
// checkbox.indeterminate = true;

///////////////////////////////////////


.cb input[type="checkbox"]:indeterminate + .cb__label::after {
    content: "O";
}
