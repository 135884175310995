//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  padding: var(--ion-breadcrumb-padding-vertical) var(--ion-breadcrumb-padding-horizontal);
  margin-bottom: var(--ion-line-height-computed);
  list-style: none;
  background-color: var(--ion-breadcrumb-bg);
  border-radius: var(--ion-border-radius-base);

  > li {
    display: inline-block;

    + li:before {
      padding: 0 5px;
      color: var(--ion-breadcrumb-color);
      // [converter] Workaround for https://github.com/sass/libsass/issues/1115
      --ion-nbsp: "\00a0";
      content: "#{var(--ion-breadcrumb-separator)}#{var(--ion-nbsp)}"; // Unicode space added since inline-block means non-collapsing white-space
    }
  }

  > .active {
    color: var(--ion-breadcrumb-active-color);
  }
}
