//
// Alerts
// --------------------------------------------------


// Base styles
// -------------------------

.alert {
  padding: var(--ion-alert-padding);
  margin-bottom: var(--ion-line-height-computed);
  border: 1px solid transparent;
  border-radius: var(--ion-alert-border-radius);

  // Headings for larger alerts
  h4 {
    margin-top: 0;
    color: inherit; // Specified for the h4 to prevent conflicts of changing var(--ion-headings-color)
  }

  // Provide class for links that match alerts
  .alert-link {
    font-weight: var(--ion-alert-link-font-weight);
  }

  // Improve alignment and spacing of inner content
  > p,
  > ul {
    margin-bottom: 0;
  }

  > p + p {
    margin-top: 5px;
  }
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

// The misspelled .alert-dismissable was deprecated in 3.2.0.
.alert-dismissable,
.alert-dismissible {
  padding-right: (var(--ion-alert-padding) + 20);

  // Adjust close link position
  .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

.alert-success {
  @include alert-variant(var(--ion-alert-success-bg), var(--ion-alert-success-border), var(--ion-alert-success-text));
}

.alert-info {
  @include alert-variant(var(--ion-alert-info-bg), var(--ion-alert-info-border), var(--ion-alert-info-text));
}

.alert-warning {
  @include alert-variant(var(--ion-alert-warning-bg), var(--ion-alert-warning-border), var(--ion-alert-warning-text));
}

.alert-danger {
  @include alert-variant(var(--ion-alert-danger-bg), var(--ion-alert-danger-border), var(--ion-alert-danger-text));
}
