@import "./vendor-prefixes";
@import "./opacity";
@import "./border-radius";
@import "./variables";

///////////////////////////////////////

// quick nav - shortcut links for main-nav

///////////////////////////////////////

.quick-nav {
	float: right;

	@media screen and (max-width: var(--ion-grid-float-breakpoint-max)) {
		margin-top: 3px;
		margin-bottom: 3px;
	}

	> li > a {
		font-size: 1.4em;
		color: var(--ion-text-color);
		transition:(color 0.25s);

		&:hover {
			cursor: pointer;
			color: var(--ion-link-color);
		}
	}

	.active a {
		color: var(--ion-link-color !important);
	}
}


///////////////////////////////////////

// navbar

///////////////////////////////////////

.navbar {
	margin-bottom: 0;
	border: 0;

	@media screen and (max-width: var(--ion-grid-float-breakpoint-max)) {
		margin-top: 0;
		background: #fff;
	}

	.container {
		position: relative;

		@media screen and (min-width: var(--ion-screen-sm-min)) {
			padding-top: 1em;
			padding-bottom: 1em;
		}
	}

	a {
		@include user-select(none);

		&:hover {
			cursor: pointer;
		}
	}

	.navbar-brand {
		display: block;
		width: 30px; // hide the "printix" text on small devices
		height: 30px;
		margin-top: 10px;
		background: url(//assets.printix.net/img/logo/logo-tungsten.svg) 0 0 no-repeat;
		background-size: 88px 30px;

		@media screen and (min-width: var(--ion-grid-float-breakpoint)) {
			height: 39px;
			width: 115px;
			margin-top: 5px;
			background-size: auto 39px;
		}
	}

	// reset bootstrap
	& .container .navbar-brand {
		margin-left: 0;
	}

	// page title is shown in the navbar on smaller devices
	.navbar-text {
		position: absolute;
		top: 50%;
		left: 50%;
		@include translate(-50%, -50%); // center on both axes
		max-width: 60%;
		margin: 0;
		text-align: center;
		line-height: 1.4;
	}

	.navbar-text__title,
	.navbar-text__subtitle {
		@include text-overflow;
	}

	.navbar-text__title {
		font-size: 1.1em;
		font-weight: 500;
	}

	.navbar-text__subtitle {
		font-size: 0.9em;
		@include opacity(0.8);
	}
}

.navbar-toggle .icon-bar {
	background-color: var(--ion-gray-dark);
}



///////////////////////////////////////

// dropdown menus

// Custom dropdown setup to deal with long dropdown menus
// exceeding the device height because of the fixed header.
// Instead we let long dropdowns scroll inside of a
// container with a max-height based on the device height

///////////////////////////////////////

.navbar .main-menu {
	right: 10px;
}

// the language menu lives inside of the main-menu as a 3rd level menu
.navbar .language-menu {
	right: -1px;
	top: -3px;
}

.language-menu-parent {
	position: static !important;
}

.navbar .open .dropdown-menu {
	position: absolute;  // needed for mobile
	float: left; // needed for mobile
	min-width: 200px;
	background-color: var(--ion-body-bg);
	border: 1px solid var(--ion-dropdown-border);
	border-radius: var(--ion-border-radius-base);
	z-index: 12000;
	box-shadow:(0 4px 12px rgba(0, 0, 0, 0.1));

	// arrow above dropdown
	&::before,
	&::after {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		border-left-color: transparent;
		border-right-color: transparent;
		border-style: none solid solid solid;
	}

		&::before {
			top: -7px;
			right: 8px;
			border-width: 0 7px 7px 7px;
			border-bottom-color: var(--ion-dropdown-border);
		}

		&::after {
			top: -6px;
			right: 7px;
			border-width: 0 8px 8px 8px;
			border-bottom-color: var(--ion-dropdown-bg);
		}

	// link list inside dropdown
	> ul {
		@extend .list-unstyled !optional;

		a {
			display: block;
		    padding: 5px 20px;
		    clear: both;
		    font-weight: normal;
		    line-height: var(--ion-line-height-base);
		    color: var(--ion-dropdown-link-color);
		    white-space: nowrap;

				&:hover,
				&:focus {
				    text-decoration: none;
				    color: var(--ion-dropdown-link-hover-color);
				    background-color: var(--ion-dropdown-link-hover-bg);
			}
		}
	}
}

// dropdown exceeds the device height - fix it!
.dropdown-menu--compact {

	ul {
		overflow-y: scroll;
		max-height: calc(85vh - #{--ion-header-height-sm});
		padding-bottom: 20px;

		@media screen and (min-width: var(--ion-screen-sm-min)) {
			max-height: calc(95vh - #{--ion-header-height-lg});
		}

		// indicate that menu items are availble out of view
		&::before {
			@extend .icon;
			content: "\f107";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1em;
			text-align: center;
			background: white;
			@include border-bottom-radius(--ion-border-radius-base);

		}
	}
}

@media only screen and (min-width: 768px) {
	.greetings {
		font-size: 80%;
		font-style: italic;
		margin-right: 20px;
		position: absolute;
		right: 0;
		top: 55px;
	}
	.hide-greeting {
		margin-right: 48px;
	}
}

@media only screen and (max-width: 767px) {
	.greetings {
		font-size: 80%;
		font-style: italic;
		margin-left: 20px;
		position: absolute;
		left: 0;
		top: 55px;
	}
	.hide-greeting {
		display: none;
	}
}
.navbar-brand-logo {
    display: block;
    width: 4.2rem;
    height: 4.6rem;
	background: url('../img/ta-new-header-logo.svg') 0 0 no-repeat;
    margin: 0;

  &.isIos {
	  margin: 0 0.5rem 0.5rem;
	}

	@media screen and (min-width: 768px) {
		height: 4.4rem;
		width: 15rem;
		background: url('../img/new-logo-tungsten.svg') 0 0 no-repeat;
		margin: 0;
	}

	&.isIos, &.isAndroid {
		background: url('../img/ta-header-logo.svg') 0 0 no-repeat;
		@media screen and (min-width: 768px) {
			background: url('../img/logo-tungsten.svg') 0 0 no-repeat;
		}
	}
}

.title-text {
	display: none;

	&.isIos, &.isAndroid {
		@media screen and (min-width: 768px) {
			display: block;
			font-size: 2.2rem;
			font-weight: 500;
			max-width: 200px;
			margin-left: 2.5rem;
		}
	}
}

.icon-menu-toggle {
	font-size: 3rem;
	margin-top: 0.4rem;

	&.ios {
		margin-top: 0;
		margin-bottom: 0.5rem;
	}
}

