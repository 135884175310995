// Contextual backgrounds

// [converter] $parent hack
@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color;
  }
  a#{$parent}:hover,
  a#{$parent}:focus {
    background-color: LightenDarkenColor($color, 10);
  }
}
