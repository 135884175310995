.thin { font-weight: 100; }
.light { font-weight: 300; }
.normal { font-weight: 400; }
.medium { font-weight: 500; }
.bold { font-weight: 700; }
.heavy { font-weight: 800; }

.page-title {
    margin-bottom: 0;
    font-size: 32px;
    font-weight: 300;
}

.page-subtitle {
    font-size: 20px;
    font-weight: 300;
}

.heading--compact-top {
    margin-top: 0;
}

.text-default {
    color: var(--ion-text-color);
}

.text-dimmed {
    color: var(--ion-text-dimmed);
}

.text-success {
    color: var(--ion-brand-success);
}

.separator-thin {
    border-width: 1px;
    max-width: 60px;
}

.separator-lg {
    @media (min-width: var(--ion-screen-sm-min)) {
        border-width: 8px;
    	max-width: 60px;
    }
}

.separator-full {
	max-width: none;
}

.separator-left { margin-left: 0; }
.separator-right { margin-right: 0; }
.separator-white { border-color: white; }
.separator-white-faded { border-color: rgba(white, 0.3); }
