.printer-status {
	display: inline-block;
	height: 8px;
	width: 8px;
	border-radius: 50%;
	vertical-align: 5%;
	margin-left: 5px;
}

.printer-status--online,
.printer-status--freedom {
	background-color: var(--ion-brand-green);
}

.printer-status--warning {
	background-color: var(--ion-brand-orange);
}

.printer-status--offline {
	background-color: var(--ion-brand-red);
}

.printer-status--undefined {
	background-color: var(--ion-ColorGray1);
}

.printer-status__label {
	// margin-left: 3px;
}

