/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

 /* Core CSS required for Ionic components to work properly */
 @import "~@ionic/angular/css/core.css";

 /* Basic CSS for apps built with Ionic */
 @import "~@ionic/angular/css/normalize.css";
 @import "~@ionic/angular/css/structure.css";
 @import "~@ionic/angular/css/typography.css";
 @import '~@ionic/angular/css/display.css';

 /* Optional CSS utils that can be commented out */
 @import "~@ionic/angular/css/padding.css";
 @import "~@ionic/angular/css/float-elements.css";
 @import "~@ionic/angular/css/text-alignment.css";
 @import "~@ionic/angular/css/text-transformation.css";
 @import "~@ionic/angular/css/flex-utils.css";

 /* Custom imports */
@import "./assets/scss/custom-bootstrap-variables";
@import "./assets/scss/variables";
@import "./assets/scss/base";
@import "./theme/animation.scss";
@import "./theme/button.scss";
@import "./theme/default.scss";
@import "./theme/form.scss";
@import "./theme/icons-default.scss";
@import "./theme/icons.scss";
@import "./assets/scss/cb-switch";
@import "./assets/scss/checkbox";
@import "./assets/scss/checkbox";
@import "./assets/scss/custom-bootstrap";
@import "./assets/scss/flex";
@import "./assets/scss/forms";
@import "./assets/scss/list";
@import "./assets/scss/loader";
@import "./assets/scss/mixins";
@import "./assets/scss/navigation";
@import "./assets/scss/opacity";
@import "./assets/scss/print";
@import "./assets/scss/printer-id";
@import "./assets/scss/printer-status";
@import "./assets/scss/radiobutton";
@import "./assets/scss/splash";
@import "./assets/scss/strike";
@import "./assets/scss/tab-menu";
@import "./assets/scss/tables";
@import "./assets/scss/toast";
@import "./assets/scss/type";
@import "./assets/scss/utilities";
@import "./assets/scss/vendor-prefixes";
// ********** //

.eula-text {
    .HeaderFooter {
        height: 1rem !important;
    }
}

$font-path: './assets/fonts';

@font-face {
  font-family: 'Lato';
  src: url("#{$font-path}/lato/LatoLatin-Light.eot"); /* IE9 Compat Modes */
  src: url("#{$font-path}/lato/LatoLatin-Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("#{$font-path}/lato/LatoLatin-Light.woff2") format("woff2"), /* Modern Browsers */
       url("#{$font-path}/lato/LatoLatin-Light.woff") format("woff"), /* Modern Browsers */
       url("#{$font-path}/lato/LatoLatin-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url("#{$font-path}/lato/LatoLatin-Regular.eot"); /* IE9 Compat Modes */
  src: url("#{$font-path}/lato/LatoLatin-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("#{$font-path}/lato/LatoLatin-Regular.woff2") format("woff2"), /* Modern Browsers */
       url("#{$font-path}/lato/LatoLatin-Regular.woff") format("woff"), /* Modern Browsers */
       url("#{$font-path}/lato/LatoLatin-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url("#{$font-path}/lato/LatoLatin-Medium.eot"); /* IE9 Compat Modes */
  src: url("#{$font-path}/lato/LatoLatin-Medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("#{$font-path}/lato/LatoLatin-Medium.woff2") format("woff2"), /* Modern Browsers */
       url("#{$font-path}/lato/LatoLatin-Medium.woff") format("woff"), /* Modern Browsers */
       url("#{$font-path}/lato/LatoLatin-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url("#{$font-path}/lato/LatoLatin-Semibold.eot"); /* IE9 Compat Modes */
  src: url("#{$font-path}/lato/LatoLatin-Semibold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("#{$font-path}/lato/LatoLatin-Semibold.woff2") format("woff2"), /* Modern Browsers */
       url("#{$font-path}/lato/LatoLatin-Semibold.woff") format("woff"), /* Modern Browsers */
       url("#{$font-path}/lato/LatoLatin-Semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url("#{$font-path}/lato/LatoLatin-Bold.eot"); /* IE9 Compat Modes */
  src: url("#{$font-path}/lato/LatoLatin-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("#{$font-path}/lato/LatoLatin-Bold.woff2") format("woff2"), /* Modern Browsers */
       url("#{$font-path}/lato/LatoLatin-Bold.woff") format("woff"), /* Modern Browsers */
       url("#{$font-path}/lato/LatoLatin-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url("#{$font-path}/lato/LatoLatin-Heavy.eot"); /* IE9 Compat Modes */
  src: url("#{$font-path}/lato/LatoLatin-Heavy.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("#{$font-path}/lato/LatoLatin-Heavy.woff2") format("woff2"), /* Modern Browsers */
       url("#{$font-path}/lato/LatoLatin-Heavy.woff") format("woff"), /* Modern Browsers */
       url("#{$font-path}/lato/LatoLatin-Heavy.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato';
  src: url("#{$font-path}/lato/LatoLatin-Black.eot"); /* IE9 Compat Modes */
  src: url("#{$font-path}/lato/LatoLatin-Black.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("#{$font-path}/lato/LatoLatin-Black.woff2") format("woff2"), /* Modern Browsers */
       url("#{$font-path}/lato/LatoLatin-Black.woff") format("woff"), /* Modern Browsers */
       url("#{$font-path}/lato/LatoLatin-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}


* {
  font-family: 'Times New Roman';
  font-family: 'Lato';

  :focus {
    outline: none;
  }
}


///////// ION_POPOVER ////////////
ion-popover {
  &.printJobMenu::part(content) { // new way to target shadow parts in Ionic 6
    width: 18rem;
  }
  &.printJobMenu {
    ion-content {
      top: 0;

      button.print-job-action-button {
        width: 100%;
        height: 40px;
        font-size: 1.4rem;
        text-align: left;
        background-color: transparent;
        border-bottom: 1px solid #e2e2e2;
        padding: 0 0 0 8px;

        &:active {
          background-color: var(--ion-brand-blue);
          color:#FFF;
        }

        & > span {
          display: inline-block;
          line-height: 2.5;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  } // end printJobMenu

  &.select-printer-menu.ios::part(content) { // new way to target shadow parts in Ionic 6
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  &.select-printer-menu {
    --width: 88vw;
    --height: 88vh;

    &::part(backdrop) {
      width: 100vw;
    }

    @media (min-width: 768px) {
      max-width: 80vw;
    }

    @media (min-width: 1400px) {
      max-width: 60vw;
    }

    ion-content {
      ion-scroll { white-space: nowrap; }

      .printqueues-list-button-container {
        display: flex;
        justify-content: center;
        background-color: #fff;
        padding: 10px 0;
        z-index: 100;

        ion-button {
          padding: 0;
          margin-bottom: 20px;

          &.printqueues-list-button {
            color: #fff;
            text-transform: unset;
            background-color: transparent;
            box-shadow: none;
            margin: 0;
          }
        }
      }

      ion-list {
        width: 100%;
        text-align: center;
        overflow-y: auto;

        ion-item.printer-list-item {

          ion-list {
            padding: 0;

            ion-item.printer-queue-container {
              border-bottom: 1px solid #adadad;

              &::part(native) {
                padding-inline-start: 0.5rem;
              }

              &.last-used-queue {
                border:1px solid var(--ion-brand-blue);
                border-radius: 5px;
              }

              button.select-printer-clickzone.printer-queue  {
                background-color: transparent;

                &.text {
                  width: calc(100% - 11.5rem);
                  font-size: 1.4rem;
                }

                .printer-id.printer-queue {
                  width: 5rem;
                  height: 5rem;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  padding: 0 6px;


                  &.adjust-signId { // adjust for printer features
                    & .sign-id, .printer-features {
                      height: 2.7rem;
                      font-size: 1.4rem;
                    }
                    & .printer-features {
                      display: flex;
                      align-items: center;
                      font-size: 1rem;
                    }
                  }
                }

                .printer-in-popover {
                  width: 40vw;
                  height: 6rem;
                  color: #333;
                  font-weight: 400;
                  text-align: left;
                  line-height: 5rem;
                  position: relative;
                  @include text-overflow;
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  margin-left: 0.7rem;

                  .queue-name {
                    line-height: 2rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }

                  .printer-location {
                    color: #9E9EA6;
                    line-height: 2rem;
                  }
                }
              }

              button.favorite-printer-clickzone {
                width: 29px;
                height: 29px;
                font-size: 110%;
                border: 1px solid rgba(79, 171, 236, 0.2);
                border-radius: 50%;
                background: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 13px;
                right: 6px;
                padding: 0;

                .favorite-printer-icon {
                  top: 0px;
                  left: 0px;

                  .favorite-color {
                    color: var(--ion-brand-blue);
                  }
                }

                &.printer-info {
                  font-size: 120%;
                  background-color:transparent;
                  right: 50px;

                  &:active {
                    background-color: rgba(0, 191, 255, 0.3);

                    & .favorite-color {
                      color: #FFF;
                    }
                  }

                  & .info-icon {
                    font-family: 'Times New Roman', Times, serif;
                    font-style: italic;
                  }
                }
              }
            }
          }
        }
      }
    }
  } // end select-printer-menu

  .alert-ios [aria-checked=true] .alert-checkbox-inner {
    left: 9px;
    top: 5px;
  }

  &.deselectPrintJobs::part(content) { // new way to target shadow parts in Ionic 6
    padding: 1.5rem;
  }
  &.deselectPrintJobs {

    ion-content {
      display: flex;
      justify-content: center;

      .deselect-text {
        text-align: center;

        h5 {
          margin: 0;
        }

        p {
          margin: 12px 0;
        }
      }

      .deselect-buttons {
        display: flex;
        justify-content: space-evenly;
        button {
          min-width: 70px;
          border-color: var(--ion-brand-blue);
        }
      }
    }
  } // end deselectPrintJobs

  &.releaseMatchingPrintJobs::part(content) { // new way to target shadow parts in Ionic 6
    padding: 1.5rem;
  }
  &.releaseMatchingPrintJobs {
    ion-content {
      display: flex;
      justify-content: center;

      .deselect-text {
        text-align: center;

        h5 {
          margin: 0;
        }

        p {
          margin: 12px 0;
        }
      }

      .deselect-buttons {
        display: flex;
        justify-content: space-evenly;
        button {
          min-width: 70px;
          border-color: var(--ion-brand-blue);
        }
      }
    }
  } // end releaseMatchingPrintJobs

  // AirPrint Password //
  &.showAirPrintPasswordsList::part(content) { // new way to target shadow parts in Ionic 6
    padding: 1.5rem;
  }
  &.showAirPrintPasswordsList {
    --width: 88vw;

    .close-btn-container {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      margin-top: 40px;

      .btn {
        display: block;
        border-color: #1478BE;
      }
    }

    .airprint-popover {
      position: relative;

      & .close {
        height: 20px;
        font-size: 20px;
        float: none;
        position: absolute;
        top: -10px;
        right: 3px;
        margin: 0;
      }

      &__header {
        margin-bottom: 40px;

        &__title {
          text-transform: uppercase;
          text-align: center;
          margin-top: 1rem;
        }
      }

      &__content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &__generated-password {
          border-top: 1px solid grey;
          border-bottom: 1px solid grey;
          user-select: text;

          ion-input {
            margin: 11px 8px 11px 6px;
            user-select: all;
          }
        }

        &__list-header {
          display: flex;
          justify-content: space-around;
          flex-wrap: nowrap;

          &__name {
            width: 50%;
            text-align: center;
          }

          &__pw {
            width: 50%;
            text-align: center;
          }
        }

        &__pw-list {

          &__item {

            & .label {
              display: flex;
              justify-content: space-around;
              flex-wrap: nowrap;

              > div {
                width: 50%;
                text-align: left;
              }
            }
          }
        }
      }
    }
  } // end showAirPrintPasswordsList

  &.securePrintEnabled::part(content), &.onDeviceRelease::part(content) { // new way to target shadow parts in Ionic 6
    padding: 2rem;
  }
  &.securePrintEnabled, &.onDeviceRelease {
    .secure-print-level-text {
      text-align: center;
      margin-bottom: 1rem;
    }

    .secure-print-level-button {
      display: flex;
      justify-content: center;
    }
  } // end securePrintEnabled

  &.unMatchedPrinter::part(content) { // new way to target shadow parts in Ionic 6
    padding: 1.5rem;
  }
  &.unMatchedPrinter {
    ion-content {
      margin-bottom: 10px !important;

      .unmatched-printer-text {
        text-align: center;
        margin-bottom: 10px;
      }

      .unmatched-printer-button {
        display: flex;
        justify-content: center;

        ion-button {
          text-transform: unset;
        }
      }
    }
  } // end unMatchedPrinter

  &.choose-scanning-method::part(content) {
    padding: 1.5rem;
  }
  &.choose-scanning-method {
    --width: 70vw;

    ion-list {
      padding: 0;

      ion-item:first-child {
        margin-bottom: 1.5rem;
      }
    }
  } // end choose-scanning-method

  &.scan-nfc-ready {
    ion-list {
      text-align: center;
      padding: 1.5rem;

      ion-label {
        text-align: center;
        font-size: 18px;
      }

      .btn {
        width: 50%;
        height: 35px;
        padding: 0;
        text-transform: unset;
        margin-top: 1.5rem;
      }
    }
  } // end scan-nfc-ready

  &.secure-print-method::part(content) {
    padding: 1.5rem;
  }
  &.secure-print-method {
    ion-list {
      text-align: center;

      h5 {
        margin: 1rem 0;
      }

      .nfc-not-available {
        color: red;
        font-size: 1.1rem;
      }

      .secure-print-method-list {
        margin: 3rem 0 4rem;

        ion-item::part(native) {
          padding-inline-start: 0;
          padding-inline-end: 0;
        }
        ion-item {
          &::part(native) {
            padding-inline-start: 0;
          }
          i {
            margin: 0 1rem;
          }

          ion-label {
            text-align: center;
            padding-bottom: 1rem;
            margin: 1rem 0;

            &.network-name {
              white-space: normal;
            }
          }
        }
      }

      & .action-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;

        &.close-button {
          justify-content: center;
        }

        ion-button {
          text-transform: unset;
        }
      }
    }
  } // end secure-print-method

  &.confirm-tag-printer {
    .list-ios > .item-block:first-child {
      border: none;
    }

    ion-list {
      padding: 1.5rem;

      .item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h {
        white-space: normal;
      }

      ion-item {
        text-align: center;
        margin-bottom: 2rem;
        white-space: normal;
      }

      ion-button {
        text-align: center;
        font-size: 16px;
        white-space: break-spaces !important;
        text-transform: unset;
      }
    }
  } // end confirm-tag-printer

  &.device-version-warning::part(content) {
    width: 300px;
  }
  &.device-version-warning {
    ion-list {
      text-align: center;

      ion-item {
        text-align: center;
        border-top: none !important;
        --border-color: none !important;

        &.go-to-store-link {
          img {
            width: 150px;
          }
        }
      }
    }
  } // end device-version-warning

  &.send-logs {
    .email-logs-button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem;

      ion-button {
        width: auto;
        height: 4rem;
        text-transform: unset;
      }
    }

    ion-item {
      border: none !important;
    }
  } // end send-logs
} // end ion-popover

// ###########################################  ###########################################  ########################################### //
// //// ION MODAL ////////////////////////////  ###########################################  ########################################### //
// ###########################################  ###########################################  ########################################### //

ion-modal {
  --background: transparent;

  &.showPrintJobInfo {
    &::part(content) { // new way to target shadow parts in Ionic 6
      box-shadow: none;
    }

    ion-item::part(native) {
      --background: transparent;
    }
    @media only screen and (min-width: 768px) and (min-height: 768px) {
      &.sc-ion-modal-md-h {
        --height: 700px;
      }
    }

    & .modal-content {
      padding: 1rem;

      .info-list__content {
        height: auto;
      }
    }

    .modal-footer {
      button {
        height: 4rem;
        color: #fff;
        background: var(--ion-color-green1);
        border-radius: 5px;
        padding: 6px 12px;
      }
    }
  } // end showPrintJobInfo

  &.printerInfo {
    @media only screen and (min-width: 768px) and (min-height: 600px) {
      --min-height: 80rem !important;
    }

    &::part(content) {
      box-shadow: none;
    }

    ion-item::part(native) {
      --background: transparent;
    }

    .show-printer-info {

      & .info-list {

        &__content {
        width: auto;
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        & .no-printer-access {
          margin-left: 7px;
          line-height: 14px;
          max-width: 90%;
          display: inline-block;
        }
      }
    }

      & .loader {
        margin: 0;
        left: 1em;

        &--spaceless {
          margin-top: 3px;
        }
      }

      .modal-footer {
        button {
          color: #fff;
          background: var(--ion-color-green1);
          border-radius: 5px;
          padding: 6px 12px;
        }
      }
    }
  } // end printerInfo

  .modal-container.content-ios {
    background-color: transparent;
  }

  h3.modal-title {
    max-width: 100%;
    line-height: 1.8;
  }

  .info-list {

    &__header {
      width: 100%;
      text-align: left;
      margin-bottom: 6px;
    }

    &__content {
      width: 100%;
      color: #4D4D4D;
      text-align: left;
      white-space: normal;
      overflow-x: auto;

      &__functions {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      & .printer-status {
        margin-left: 0;
        margin-right: 5px;
      }

      & .status-text-placement {
        display: inline-block;
        margin: 0 0 5px;
      }
    }
  } // end info-list

  &.selectPrintQueue {

    ion-item::part(native) {
      --background: transparent;
    }

    ion-item {
      & .modal-content {
        width: 100%;
        background-color: #FFF;
        max-height: 90vh;
        overflow-y: scroll;

        .modal-header {
          .select-printer-clickzone {
            width: 100%;
            display: flex;
            position: relative;

            .printer-id {
              .sign-id {
                height: 1rem;
                display: block;
                margin-top: -3px;
              }

              .printer-features {
                display: inline-block;
              }
            }

            .printer-in-popover {
              width: 53vw;
              margin-left: 0.7rem;

              .select-queue-printer-name {
                line-height: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .printer-status-container {
                line-height: 2;

                .printer-status {
                  margin-left: 0px !important;
                }

                .status-text-placement {
                  line-height: 0.9;
                  position: unset;
                  margin-left: 1rem !important;
                }

                & > span {
                  display: inline-block;
                }
              }

              & .loader {
                position: absolute;
                top: 0.1rem;
                left: 5.5rem;
                margin-top: 0;
              }
            }
          }

          .close {
            font-size: 2.3rem;
            position: absolute;
            top: 0.8rem;
            right: 0px;
            margin-top: 0!important;
            --box-shadow: none;
          }
        }

        .modal-body.print-queue-list {
          padding: 0 0 2rem 0;

          .sub-title {
            border-bottom: 1px solid #efefef;
            padding: 0.5rem 0 0.5rem 1rem;
            text-align: left;

            .showJobs-btn {
              padding-left: 1rem;
              background: transparent;

              > span {
                margin-right: 0.5rem;
              }
            }

            .printJobsList {
              margin-top: 0.5rem;
              font-size: 90%;

              .printjob-subtitle {
                width: 87%;
                @include text-overflow();
                display: inline-block;
                margin-bottom: 0.5rem;
                margin-left: 1.5rem;
              }
            }
          }

          .no-print-queue {
            padding: 20px 0;
          }

          .queue-row {
            border-bottom: 1px solid #efefef;
            position: relative;
            height: 63px;
            padding: 10px 20px 10px 20px;

            &:nth-child(1) {
              border: none;
            }

            .name {
              display: inline-block;
              color: #4b4b4b;
              font-weight: 400;
              font-size: 89%;
              text-align: left;
              max-width: 76%;
              white-space: normal;
            }

            .action-btn {
              position: absolute;
              right: 1rem;
              bottom: 1rem;
              width: 8rem;
            }
          }

          &.no-printer-access {
            padding: 2rem 2rem 0.5rem;
          }
        }
      }
    }
  } // end selectPrintQueue

  &.networksList {
    &::part(content) {
      box-shadow: none;
    }
    & ion-list {
      width: 95%;
      text-align: center;
      background-color: #FFF;
      border-radius: var(--ion-border-radius-base);
      padding: 10px;
      margin: 15% auto 0 !important;

      .networks-list-title {
        display: block;
        font-size: 20px;
      }

      .network-list-container {
        max-height: 55vh;
        overflow: auto;
        padding: 0 0 20px 15px;
        padding-left: 15px;

        ion-item:hover {
          background-color: #1478BE;
        }
      }

      .button-container {
        ion-button {
          text-transform: unset;
        }
      }

      ion-item {
        background-color: #fff;

        ion-searchbar {
          width: 100%;
          background-color: transparent;
          border-top: 1px solid rgb(238, 238, 238);
          border-bottom: 1px solid rgb(238, 238, 238);
          margin-left: -1%;

          .searchbar-search-icon {
              top: 3px;
          }

          .searchbar-input {
              box-shadow: none;
              padding: 4px 55px;
              line-height: 20px;
          }
        }

        .network-name {
          text-align: left;
        }

        .network-checkbox {
          margin-left: 18px !important;
        }
      }

      &.networks-list-buttons {
        text-align: center;
        ion-button {
          text-transform: unset;
        }

        .pagination-container {
          text-align: center;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .networksList {
      .modal-wrapper {
        height: 1000px;
        top: calc(50% - (1000px/2));
        ion-list {
          .network-list-container {
            max-height: 650px;
          }
        }
      }
    }
  } // end networksList
} // end ion-modal


// #### HTTP_ERROR_MODAL ########## //

ion-modal.http-error-modal {
  &::part(content) {
    box-shadow: none;
  }

  ion-card.http-error-modal {
    width: 100%;
    background-color: #E94D4C !important;
    margin: 0 !important;

    &.internal-error {
      background-color: #F49500 !important;
    }

    ion-card-header {
      color: #000;
      position: relative;
      padding: 10px 10px !important;

      .icon-warning {
        font-size: 18px;
        vertical-align: text-bottom;
        padding-right: 10px;

        &.server-error {
          display: block;
          padding: 3px 0;
        }
      }

      & .try-again {
        display: block;
      }

      .icon-angle-down {
        font-size: 18px;
        margin-top: 5px;

        &.server-error {
          display: block;
          margin-top: 0;
        }
      }

      .icon-close {
        width: 40px;
        height: 80%;
        position: absolute;
        right: 0;
        top: 5px;
        padding-top: 10px;

        &.server-error {
          top: 0;
        }
      }

      .error-modal-title {
        padding: 10px 0;

        &.server-error {
          width: 70%;
          text-align: center;
          overflow: hidden;
          display: block;
          white-space: normal;
          padding: 0;
          margin: auto;
        }
      }
    }

    ion-card-content {
      // max-height: 0;
      color: #4e4e4e;
      background-color: #e9e9e9;
      text-align: left;
      overflow: hidden;
      padding: 0;
      user-select: all;

      .error-message-content-inner {
        overflow-y: auto;
        padding: 15px 10px;

        & > div {
          &:last-child{
            .error-message-content-title{
              margin-bottom: 0;
            }
          }

          .error-message-content-title {
            font-weight: bold;
            display: inline-block;
          }

          .error-message-content-text {
            user-select: all;
          }
        }
      }
    }
  } // end http-error-modal
} // end ion-modal


// ###########################################  ###########################################  ########################################### //
// //// ALERT DIALOG ////////////////////////////  ###########################################  ########################################### //
// ###########################################  ###########################################  ########################################### //

.show-confirm-dialog {

  .alert-wrapper {
    border-radius: 10px;

    .alert-head {
      h2 {
        text-align: center;
      }
    }

    .alert-message {
      font-size: 18px;
      text-align: center;
    }
  }

  .alert-button-group {
    justify-content: space-between;
    padding: 24px;

    .alert-button {
      color: var(--ion-color-primary);
      border: 2px solid var(--ion-color-primary);
      border-radius: 10px;
      padding: 10px 20px;
      flex: none;
      min-width: 10px;

      .alert-button-inner {
        justify-content: center;
      }
    }
  }
} // end show-confirm-dialog

// ###########################################  ###########################################  ########################################### //
// //// ERROR_NETWORK ////////////////////////  ###########################################  ########################################### //
// ###########################################  ###########################################  ########################################### //

.error-network {
  background-color: #4D4D4D !important;
  display: flex;
  justify-content: center;
  padding: 10px 20px;

  .error-network-container {
    color: #fff;
    display: flex;
    align-items: center;
    margin: auto;

    .icon-warning {
      width: 40px;
      height: 28px;
      font-size: 25px;
      display: flex;
      align-items: center;
    }
  }
} // end error-network