/*

    Angular Toastr - custom template


    <div class="{{toastClass}} {{toastType}}" ng-click="tapToast()">
        <div ng-switch on="allowHtml">
            <div ng-switch-default ng-if="title" class="{{titleClass}}" aria-label="{{title}}">{{title}}</div>
            <div ng-switch-default class="{{messageClass}}" aria-label="{{message}}">{{message}}</div>
            <div ng-switch-when="true" ng-if="title" class="{{titleClass}}" ng-bind-html="title"></div>
            <div ng-switch-when="true" class="{{messageClass}}" ng-bind-html="message"></div>
        </div>
        <progress-bar ng-if="progressBar"></progress-bar>
    </div>

*/

// YAAAAAY!!! Let's start out by adding specificity 1000! Thank you Toastr
#toast-container {
    position: fixed;
    z-index: 999999;
    @extend .toast--top-full-width;

    @media screen and (max-width: var(--ion-screen-xs-max)) {
        width: 100%;
    }
}

.toast {
    position: relative;
    overflow: hidden;
    height: 51px;
    padding: 3px 25px 5px 60px;
    font-size: 0.9em;
    color: #fff;
    background-color: #030303;
    @include user-select(none);
    @include flexbox();
    @include align-items(center);

    // icons
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 100%;
        width: 45px;
        background: rgba(0,0,0,0.1);
    }

    // bg
    &::after {
        @extend .icon;
        position: absolute;
        left: 13px;
        top: 50%;
        font-size: 1.3em;
        @include translate(0,-50%);
        vertical-align: middle;
    }

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    @media screen and (min-width: var(--ion-screen-sm-min)) {
        min-height: auto;
        height: 35px;
        font-size: 0.95em;
    }
}


///////////////////////////////////////

// elements

///////////////////////////////////////

.toast__title {
    font-weight: 600;
}

.toast__message {
    word-wrap: break-word;

    a,
    label {
        color: #fff;
    }

    a:hover {
        color: #ccc;
        text-decoration: none;
    }
}

.toast__close-btn {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.8;

    &:hover,
    &:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
      opacity: 0.4;
    }
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast__close-btn {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
}



///////////////////////////////////////

// states

///////////////////////////////////////

.toast--success {
    background-color: var(--ion-brand-success);

    &::after {
        content: var(--ion-icon-ok);
    }
}

.toast--error {
    background-color: var(--ion-brand-danger);

    &::after {
        content: var(--ion-icon-ban);
    }
}

.toast--info {
    background-color: var(--ion-brand-info);

    &::after {
        content: var(--ion-icon-info);
        font-size: 105%;
    }
}

.toast--warning {
    background-color: var(--ion-brand-warning);
}

.toast__progress-bar {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000;
    opacity: 0.4;
}


///////////////////////////////////////

// animations

///////////////////////////////////////

#toast-container [toast] {
    opacity: 1 !important;
    transition:(opacity .3s);

    &.ng-enter,
    &.ng-leave.ng-leave-active {
        opacity: 0 !important;
    }

    &.ng-leave,
    &.ng-enter.ng-enter-active {
        opacity: 1 !important;
    }
}


///////////////////////////////////////

// positioning

///////////////////////////////////////

.toast--top-center {
    width: 100%;
    top: 0;
    right: 0;

    @media screen and (min-width: var(--ion-screen-sm-min)) {

        .toast {
            width: 400px;
        }
    }
}

.toast--bottom-center {
    bottom: 0;
    right: 0;
}

.toast--top-left {
    top: 10px;
    left: 10px;
}

.toast--top-right {
    top: 10px;
    right: 10px;
}

.toast--bottom-right {
    right: 10px;
    bottom: 10px;
}

.toast--bottom-left {
    bottom: 10px;
    left: 10px;
}

.toast--top-center .toast,
.toast--bottom-center .toast {
    margin-left: auto;
    margin-right: auto;
}

.toast--top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

.toast--bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast--top-full-width .toast,
.toast--bottom-full-width .toast {
    margin-left: auto;
    margin-right: auto;
}
