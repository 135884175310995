[error-message-container] {
  color: var(--ion-invalid-color) !important;
}

form.ng-submitted ion-item.hg-invalid {
  ion-label {
    color: var(--ion-invalid-color) !important;
  }

  input {
    color: var(--ion-invalid-color) !important;
  }

  .item-inner {
    border-color: var(--ion-invalid-color) !important;
  }
}