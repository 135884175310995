.loader {
  position: relative;
  margin: 3em auto;
  height: 1em;
  width: 4em;
  text-align: center;

  @media screen and (min-width:  var(--ion-grid-float-breakpoint)) { // 768px
    width: 5em;
  }
}

.loader--compact {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.loader--spaceless{
  margin-bottom: 0;
  margin-top: 9px;
}

.loader > span,
.loader::before,
.loader::after {
  display: inline-block;
  width: 12px;
  height: 12px;
  // background-color:  var(--ion-gray-darker;
  background-color:#333;
  border-radius: 100%;
  @include transform(translateZ(0));
  @include animation(loader-bounce 1.5s infinite ease-in-out both);

  @media screen and (min-width:  var(--ion-grid-float-breakpoint)) {
    width: 18px;
    height: 18px;
  }
}

.loader::before,
.loader::after {
  content: "";
  position: absolute;
  top: 0;
}

// 1st
.loader::before {
  left: 0;
  @include animation-delay(-0.32s);
}

// 2nd
.loader > span  {
  vertical-align: top;
  @include animation-delay(-0.16s);
}

// 3rd
.loader::after {
  right: 0;
}

@-webkit-keyframes loader-bounce {
  0%, 80%, 100% { @include scale(0) }
  40% { @include scale(1) }
}

@keyframes loader-bounce {
  0%, 80%, 100% { @include scale(0) }
  40% {
    @include scale(1);
  }
}

@-webkit-keyframes loader-bounce2 {
    0%, 80%, 100% { @include translate(0,0) }
    40% { @include translate(-100%,0) }
}

@keyframes loader-bounce2 {
    0%, 80%, 100% { @include translate(0,0) }
    40% { @include translate(0,-200%) }
}

.loader--sm {
  width: 3em;
  display: inline-block;
  vertical-align: text-bottom;
}

.loader--sm > span,
.loader--sm::before,
.loader--sm::after {
  width: 8px;
  height: 8px;
}



///////////////////////////////////////

// spinner

///////////////////////////////////////

// markup

/*
<div class="loader--spinner">
    <svg class="loader__spinner" viewBox="25 25 50 50">
        <circle class="loader__path" cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10"/>
    </svg>
</div>
*/



// container
.loader--spinner {
  position: relative;
  margin: 3em auto;
  width: 40px;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.loader--xs {
    width: 14px;
}

.loader__spinner {
    animation: rotate 2s linear infinite;
    height: 50px;
    transform-origin: center center;
    width: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.loader__path {
    stroke-dasharray: 2, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite; //, color 6s ease-in-out infinite;
    stroke-linecap: round;
    stroke:  var(--ion-brand-primary);
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

// @keyframes color {
//   0% {
//     stroke:  var(--ion-brand-primary;
//   }
//   50% {
//     stroke:  var(--ion-brand-orange;
//   }
//   100% {
//       stroke:  var(--ion-brand-primary;
//   }
// }


///////////////////////////////////////

// global loader

///////////////////////////////////////

.global-loader {
	position: absolute;
	top: 12px;
	right:  var(--ion-grid-gutter-width / 2);
    overflow: hidden;
    white-space: nowrap;
    @extend .hidden-xs !optional;


	.loader--spinner {
		display: inline-block;
		margin: 0;
	}
}

.global-loader__label {
    display: inline-block;
	margin-left: 5px;
	font-size: 0.9em;
	white-space: nowrap;
	vertical-align: 10%;
	color:  var(--ion-brand-primary);
    transition:(opacity 0.2s, transform 0.4s);
}

.global-loader__spinner {
    @extend .loader--spinner;
    @extend .loader--xs;
    transition:(opacity 0.2s 0.4s, transform 0.4s 0.4s);
}


// inactive state
.global-loader--inactive {

    .global-loader__label  {
        @include opacity(0);
        @include translate(100%, 0);
        transition:(opacity 0.2s 0.2s, transform 0s 0.6s); // out transition
    }

    .global-loader__spinner  {
        @include opacity(0);
        @include scale(0.2);
        transition:(opacity 0.2s 0s, transform 0s 0.6s); // out transition
    }
}


