@import "../scss/variables";

@mixin transform ($values) {
  -webkit-transform: $values;
      -ms-transform: $values; // IE9 only
       -o-transform: $values;
          transform: $values;
}


// center vertically and/or horizontally an absolute positioned element
@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    @include transform(translateX(-50%) translateY(-50%));
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    @include transform(translateX(-50%));
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    @include transform(translateY(-50%));
  }
}

@mixin center-block() {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

@mixin nav-divider($color: #e5e5e5) {
  height: 1px;
  margin: calc(calc(var(--ion-line-height-computed) / 2) - 1) 0;
  overflow: hidden;
  background-color: $color;
}

// antialiasing mode font rendering

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// define a different bounce animation for h1 and p elements

@mixin bounce($animation, $value1, $value2) {
  @include keyframes($animation) {
    0% {
      opacity: .2;
      @include transform(translateX($value1));
    }
    60% {
      opacity: .7;
      @include transform(translateX($value2));
    }
    100% {
      opacity: 1;
      @include transform(translateX(0));
    }
  }
}

// define different cut animations

@mixin cut($animation, $value) {
  @include keyframes($animation) {
    0% {
      opacity: 1;
      @include transform(translateX($value));
    }

    100% {
      opacity: 1;
      @include transform(translateX(0));
    }
  }
}

// define different reveal animations

@mixin reveal($animation, $value) {
  @include keyframes($animation) {
    0% {
      opacity: 1;
      @include transform(translateY($value));
    }

    100% {
      opacity: 1;
      @include transform(translateY(0));
    }
  }
}

// define different mask-2 animations

@mixin mask2($animation, $value) {
  @include keyframes($animation) {
    0% {
      @include transform(translateX($value));
    }

    100% {
      @include transform(translateX(0));
    }
  }
}

// Text overflow
// Requires inline-block or block for proper styling

@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
