@import "./navs";
:root {
	--ion-tab-menu-breakpoint: 350px;  // where does the text labels collapse/disappear
	--ion-tab-menu-breakpoint-max: (--ion-tab-menu-breakpoint - 1);
}
// tab navigation and content
.tab-menu > .container {
	// remove padding on small devices
	@media screen and (max-width: var(--ion-grid-float-breakpoint-max)) {
		padding: 0;
		padding: 0;
	}
}

.tab-menu > ul {
	@media screen and (max-width: var(--ion-grid-float-breakpoint-max)) {
		margin-left: ---ion-grid-gutter-width/2;
		margin-right: ---ion-grid-gutter-width/2;
	}
}

.tab-menu ul,
.tab-menu > ul {
	@extend .nav;
	@include flexbox();
	border-bottom: 1px solid var(--ion-separator-color);

	// use flex only on small devices (hopefully a mobile browser)
	@media screen and (min-width: var(--ion-grid-float-breakpoint)) {
		display: block;
	}
}

// overriding bootstrap really sucks the donkey's cock
.tab-menu .tab-menu__tab {
	@media screen and (max-width: var(--ion-screen-xs-max)) {
		@include flex(1 1 auto);
	}

	// set fixed tab width on larger displays
    @media screen and (min-width: var(--ion-screen-sm-min)) {
    	display: block;
    	width: auto;
		float: left;

		+ .tab-menu__tab {
			margin-left: .5em;
		}
    }

	> a {
		text-align: center;
		font-weight: 600;
		font-size: 0.9em;
		padding: 1.4em 0;
		color: var(--ion-text-color !important);
		outline: 0;
		@include user-select(none);
		transition:(all 0.25s);

	    @media screen and (min-width: var(--ion-tab-menu-breakpoint)) {
			// min-height: 5em;  // needed when overflow menu item is present
			padding: 0.8em var(--ion-grid-gutter-width/2);
	    }

		&:hover {
			border: 0;
			color: var(--ion-text-color);
			cursor: pointer;
		}
	}
}

.tab-menu__tab-label {
	@media screen and (max-width: var(--ion-tab-menu-breakpoint)) {
		display: none;
	}
}

.tab-menu__icon {
	display: block;
	margin-bottom: 2px;
	font-size: 2rem;
	line-height: 1.1;
	color: var(--ion-text-color);
	transition:(transform 0.25s);
}

// overflow menu doesn't have text ...
.tab-menu__overflow-tab .tab-menu__icon {
	@media screen and (min-width: var(--ion-tab-menu-breakpoint)) {
		line-height: 2.1;
	}
}

///////////////////////////////////////

// active state

///////////////////////////////////////

// active/chosen tab - override bootstrap is always a trip
.tab-menu__tab.active > a {
	border-width: 0; // reset bootstrap borders
	box-shadow:(inset 0 -3px 0 var(--ion-brand-blue));

	// a little nifty effect never hurt anyone
	.tab-menu__icon {
		@include translate(0, -3px);
	}
}

.tab-content {
	@media screen and (min-width: var(--ion-grid-float-breakpoint)) {
		padding-top: 2em;
	}
}
