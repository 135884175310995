/**************************************************/
/* <hr> simulation with text in the middle */
/**************************************************/
.strike {
  margin: var(--ion-grid-gutter-width 0 var(--ion-grid-gutter-width) 0);
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  color: var(--ion-text-dimmed);
}

.strike > span {
  position: relative;
  display: inline-block;
  text-transform: lowercase;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background-color: var(--ion-separator-color);
}

.strike > span:before {
  right: 100%;
  margin-right: var(--ion-grid-gutter-width/2);
}

.strike > span:after {
  left: 100%;
  margin-left: var(--ion-grid-gutter-width/2);
}
