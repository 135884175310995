// Utility/helper classes

// margin top
.mt       { @extend .mt50; }
.mt-5      { margin-top: -5px; }
.mt-10     { margin-top: -10px; }
.mt-15     { margin-top: -15px; }
.mt-20     { margin-top: -20px; }
.mt-25     { margin-top: -25px; }
.mt-30     { margin-top: -30px; }
.mt-35     { margin-top: -35px; }
.mt-40     { margin-top: -40px; }
.mt-45     { margin-top: -45px; }
.mt-50     { margin-top: -50px; }
.mt-55     { margin-top: -55px; }
.mt-60     { margin-top: -60px; }
.mt-65     { margin-top: -65px; }
.mt-70     { margin-top: -70px; }
.mt-75     { margin-top: -75px; }
.mt-80     { margin-top: -80px; }
.mt-85     { margin-top: -85px; }
.mt-90     { margin-top: -90px; }
.mt-95     { margin-top: -95px; }
.mt-100    { margin-top: -100px; }
.mt0      { margin-top: 0; }
.mt5      { margin-top: 5px; }
.mt10     { margin-top: 10px; }
.mt15     { margin-top: 15px; }
.mt20     { margin-top: 20px; }
.mt25     { margin-top: 25px; }
.mt30     { margin-top: 30px; }
.mt35     { margin-top: 35px; }
.mt40     { margin-top: 40px; }
.mt45     { margin-top: 45px; }
.mt50     { margin-top: 50px; }
.mt55     { margin-top: 55px; }
.mt60     { margin-top: 60px; }
.mt65     { margin-top: 65px; }
.mt70     { margin-top: 70px; }
.mt75     { margin-top: 75px; }
.mt80     { margin-top: 80px; }
.mt85     { margin-top: 85px; }
.mt90     { margin-top: 90px; }
.mt95     { margin-top: 95px; }
.mt100    { margin-top: 100px; }

// margin bottom
.mb       { @extend .mb50; }
.mb0      { margin-bottom: 0; }
.mb-5      { margin-bottom: -5px; }
.mb-10     { margin-bottom: -10px; }
.mb-15     { margin-bottom: -15px; }
.mb-20     { margin-bottom: -20px; }
.mb-25     { margin-bottom: -25px; }
.mb-30     { margin-bottom: -30px; }
.mb-35     { margin-bottom: -35px; }
.mb-40     { margin-bottom: -40px; }
.mb-45     { margin-bottom: -45px; }
.mb-50     { margin-bottom: -50px; }
.mb-55     { margin-bottom: -55px; }
.mb-60     { margin-bottom: -60px; }
.mb-65     { margin-bottom: -65px; }
.mb-70     { margin-bottom: -70px; }
.mb-75     { margin-bottom: -75px; }
.mb-80     { margin-bottom: -80px; }
.mb-85     { margin-bottom: -85px; }
.mb-90     { margin-bottom: -90px; }
.mb-95     { margin-bottom: -95px; }
.mb-100    { margin-bottom: -100px; }
.mb5      { margin-bottom: 5px; }
.mb10     { margin-bottom: 10px; }
.mb15     { margin-bottom: 15px; }
.mb20     { margin-bottom: 20px; }
.mb25     { margin-bottom: 25px; }
.mb30     { margin-bottom: 30px; }
.mb35     { margin-bottom: 35px; }
.mb40     { margin-bottom: 40px; }
.mb45     { margin-bottom: 45px; }
.mb50     { margin-bottom: 50px; }
.mb55     { margin-bottom: 55px; }
.mb60     { margin-bottom: 60px; }
.mb65     { margin-bottom: 65px; }
.mb70     { margin-bottom: 70px; }
.mb75     { margin-bottom: 75px; }
.mb80     { margin-bottom: 80px; }
.mb85     { margin-bottom: 85px; }
.mb90     { margin-bottom: 90px; }
.mb95     { margin-bottom: 95px; }
.mb100    { margin-bottom: 100px; }

// margin top and bottom
.mtb0     { margin-top: 0; margin-bottom: 0; }
.mtb      { @extend .mt, .mb; }
.mtb10    { @extend .mt10, .mb10; }
.mtb20    { @extend .mt20, .mb20; }
.mtb30    { @extend .mt30, .mb30; }
.mtb40    { @extend .mt40, .mb40; }
.mtb50    { @extend .mt50, .mb50; }
.mtb60    { @extend .mt60, .mb60; }
.mtb70    { @extend .mt70, .mb70; }
.mtb80    { @extend .mt80, .mb80; }
.mtb90    { @extend .mt90, .mb90; }
.mtb100   { @extend .mt100, .mb100; }

.mr       { @extend .mb50; }
.mr0      { margin-right: 0; }
.mr-5      { margin-right: -5px; }
.mr-10     { margin-right: -10px; }
.mr-15     { margin-right: -15px; }
.mr-20     { margin-right: -20px; }
.mr-25     { margin-right: -25px; }
.mr-30     { margin-right: -30px; }
.mr-35     { margin-right: -35px; }
.mr-40     { margin-right: -40px; }
.mr-45     { margin-right: -45px; }
.mr-50     { margin-right: -50px; }
.mr-55     { margin-right: -55px; }
.mr-60     { margin-right: -60px; }
.mr-65     { margin-right: -65px; }
.mr-70     { margin-right: -70px; }
.mr-75     { margin-right: -75px; }
.mr-80     { margin-right: -80px; }
.mr-85     { margin-right: -85px; }
.mr-90     { margin-right: -90px; }
.mr-95     { margin-right: -95px; }
.mr-100    { margin-right: -100px; }
.mr5      { margin-right: 5px; }
.mr10     { margin-right: 10px; }
.mr15     { margin-right: 15px; }
.mr20     { margin-right: 20px; }
.mr25     { margin-right: 25px; }
.mr30     { margin-right: 30px; }
.mr35     { margin-right: 35px; }
.mr40     { margin-right: 40px; }
.mr45     { margin-right: 45px; }
.mr50     { margin-right: 50px; }
.mr55     { margin-right: 55px; }
.mr60     { margin-right: 60px; }
.mr65     { margin-right: 65px; }
.mr70     { margin-right: 70px; }
.mr75     { margin-right: 75px; }
.mr80     { margin-right: 80px; }
.mr85     { margin-right: 85px; }
.mr90     { margin-right: 90px; }
.mr95     { margin-right: 95px; }
.mr100    { margin-right: 100px; }

.ml       { @extend .mb50; }
.ml0      { margin-left: 0; }
.ml-5      { margin-left: -5px; }
.ml-10     { margin-left: -10px; }
.ml-15     { margin-left: -15px; }
.ml-20     { margin-left: -20px; }
.ml-25     { margin-left: -25px; }
.ml-30     { margin-left: -30px; }
.ml-35     { margin-left: -35px; }
.ml-40     { margin-left: -40px; }
.ml-45     { margin-left: -45px; }
.ml-50     { margin-left: -50px; }
.ml-55     { margin-left: -55px; }
.ml-60     { margin-left: -60px; }
.ml-65     { margin-left: -65px; }
.ml-70     { margin-left: -70px; }
.ml-75     { margin-left: -75px; }
.ml-80     { margin-left: -80px; }
.ml-85     { margin-left: -85px; }
.ml-90     { margin-left: -90px; }
.ml-95     { margin-left: -95px; }
.ml-100    { margin-left: -100px; }
.ml5      { margin-left: 5px; }
.ml10     { margin-left: 10px; }
.ml15     { margin-left: 15px; }
.ml20     { margin-left: 20px; }
.ml25     { margin-left: 25px; }
.ml30     { margin-left: 30px; }
.ml35     { margin-left: 35px; }
.ml40     { margin-left: 40px; }
.ml45     { margin-left: 45px; }
.ml50     { margin-left: 50px; }
.ml55     { margin-left: 55px; }
.ml60     { margin-left: 60px; }
.ml65     { margin-left: 65px; }
.ml70     { margin-left: 70px; }
.ml75     { margin-left: 75px; }
.ml80     { margin-left: 80px; }
.ml85     { margin-left: 85px; }
.ml90     { margin-left: 90px; }
.ml95     { margin-left: 95px; }
.ml100    { margin-left: 100px; }


// padding top
.pt       { @extend .pt50; }
.pt0      { padding-top: 0; }
.pt5      { padding-top: 5px!important; }
.pt10     { padding-top: 10px; }
.pt20     { padding-top: 20px; }
.pt30     { padding-top: 30px; }
.pt40     { padding-top: 40px; }
.pt50     { padding-top: 50px; }
.pt60     { padding-top: 60px; }
.pt70     { padding-top: 70px; }
.pt80     { padding-top: 80px; }
.pt90     { padding-top: 90px; }
.pt100    { padding-top: 100px; }

// padding bottom
.pb       { @extend .pb50; }
.pb0      { padding-bottom: 0; }
.pb5      { padding-bottom: 5px!important; }
.pb10     { padding-bottom: 10px!important; }
.pb20     { padding-bottom: 20px!important; }
.pb30     { padding-bottom: 30px!important; }
.pb40     { padding-bottom: 40px!important; }
.pb50     { padding-bottom: 50px!important; }
.pb60     { padding-bottom: 60px!important; }
.pb70     { padding-bottom: 70px!important; }
.pb80     { padding-bottom: 80px!important; }
.pb90     { padding-bottom: 90px!important; }
.pb100    { padding-bottom: 100px!important; }

// padding top and bottom
.ptb      { @extend .pt, .pb; }
.ptb0     { padding-top: 0; padding-bottom: 0; }
.ptb10    { @extend .pt10, .pb10; }
.ptb20    { @extend .pt20, .pb20; }
.ptb30    { @extend .pt30, .pb30; }
.ptb40    { @extend .pt40, .pb40; }
.ptb50    { @extend .pt50, .pb50; }
.ptb60    { @extend .pt60, .pb60; }
.ptb70    { @extend .pt70, .pb70; }
.ptb80    { @extend .pt80, .pb80; }
.ptb90    { @extend .pt90, .pb90; }
.ptb100   { @extend .pt100, .pb100; }

// padding left
.pl0      { padding-left: 0!important; }
.pl5      { padding-left: 5px!important; }
.pl10     { padding-left: 10px!important; }
.pl15     { padding-left: 15px!important; }
.pl20     { padding-left: 20px!important; }
.pl25     { padding-left: 25px!important; }
.pl30     { padding-left: 30px!important; }
.pl35     { padding-left: 35px!important; }
.pl40     { padding-left: 40px!important; }
.pl45     { padding-left: 45px!important; }
.pl50     { padding-left: 50px!important; }
.pl55     { padding-left: 55px!important; }
.pl60     { padding-left: 60px!important; }
.pl65     { padding-left: 65px!important; }
.pl70     { padding-left: 70px!important; }
.pl75     { padding-left: 75px!important; }
.pl80     { padding-left: 80px!important; }
.pl85     { padding-left: 85px!important; }
.pl90     { padding-left: 90px!important; }
.pl95     { padding-left: 95px!important; }
.pl100    { padding-left: 100px!important; }

// padding right
.pr0      { padding-right: 0!important; }
.pr5      { padding-right: 5px!important; }
.pr10     { padding-right: 10px!important; }
.pr15     { padding-right: 15px!important; }
.pr20     { padding-right: 20px!important; }
.pr25     { padding-right: 25px!important; }
.pr30     { padding-right: 30px!important; }
.pr35     { padding-right: 35px!important; }
.pr40     { padding-right: 40px!important; }
.pr45     { padding-right: 45px!important; }
.pr50     { padding-right: 50px!important; }
.pr55     { padding-right: 55px!important; }
.pr60     { padding-right: 60px!important; }
.pr65     { padding-right: 65px!important; }
.pr70     { padding-right: 70px!important; }
.pr75     { padding-right: 75px!important; }
.pr80     { padding-right: 80px!important; }
.pr85     { padding-right: 85px!important; }
.pr90     { padding-right: 90px!important; }
.pr95     { padding-right: 95px!important; }
.pr100    { padding-right: 100px!important; }

.p5      { padding: 5px!important; }
.p10     { padding: 10px!important; }
.p15     { padding: 15px!important; }
.p20     { padding: 20px!important; }
.p25     { padding: 25px!important; }
.p30     { padding: 30px!important; }
.p35     { padding: 35px!important; }
.p40     { padding: 40px!important; }
.p45     { padding: 45px!important; }
.p50     { padding: 50px!important; }
.p55     { padding: 55px!important; }
.p60     { padding: 60px!important; }
.p65     { padding: 65px!important; }
.p70     { padding: 70px!important; }
.p75     { padding: 75px!important; }
.p80     { padding: 80px!important; }
.p85     { padding: 85px!important; }
.p90     { padding: 90px!important; }
.p95     { padding: 95px!important; }
.p100    { padding: 100px!important; }

// font size
.size50     { font-size: 50%; }
.size60     { font-size: 60%; }
.size70     { font-size: 70%; }
.size80     { font-size: 80%; }
.size90     { font-size: 90%; }
.size100     { font-size: 100%; }
.size110     { font-size: 110%; }
.size120     { font-size: 120%; }
.size130     { font-size: 130%; }
.size140     { font-size: 140%; }
.size150     { font-size: 150%; }
.size160     { font-size: 160%; }
.size170     { font-size: 170%; }
.size180     { font-size: 180%; }
.size190     { font-size: 190%; }
.size200     { font-size: 200%; }
.size210     { font-size: 210%; }
.size220     { font-size: 220%; }
.size230     { font-size: 230%; }
.size240     { font-size: 240%; }
.size250     { font-size: 250%; }

.weigth100  { font-weight: 100; }     
.weigth200  { font-weight: 200; }     
.weigth300  { font-weight: 300; }     
.weigth400  { font-weight: 400; }     
.weigth500  { font-weight: 500; }     
.weigth600  { font-weight: 600; }     
.weigth700  { font-weight: 700; }     
.weigth800  { font-weight: 800; }     
.weigth900  { font-weight: 900; }     

.width10    { width: 10%; }
.width20    { width: 20%; }
.width30    { width: 30%; }
.width40    { width: 40%; }
.width50    { width: 50%; }
.width60    { width: 60%; }
.width70    { width: 70%; }
.width80    { width: 80%; }
.width90    { width: 90%; }
.width100   { width: 100%; }

.z100        { z-index: 100;}
.z200        { z-index: 200;}
.z300        { z-index: 300;}
.z400        { z-index: 400;}
.z500        { z-index: 500;}
.z600        { z-index: 600;}
.z700        { z-index: 700;}
.z800        { z-index: 800;}
.z900        { z-index: 900;}
.z1000       { z-index: 1000;}

.op10       { opacity: .1;}
.op20       { opacity: .2;}
.op30       { opacity: .3;}
.op40       { opacity: .4;}
.op50       { opacity: .5;}
.op60       { opacity: .6;}
.op70       { opacity: .7;}
.op80       { opacity: .8;}
.op90       { opacity: .9;}

.unselectable {
  user-select: none;
}

.no-cursor {
  cursor: default!important;
}

.text-center {
  text-align: center!important;
}

.text-left {
  text-align: left!important;
}

.text-right {
  text-align: right!important;
}

.clickable-text {
  text-decoration: underline!important;
}

.fl-right {
  float: right;
}

.fl-left {
  float: left;
}

.line-through {
  text-decoration: line-through;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.atop {
  top: 0px;
}

.abottom {
  bottom : 0px;
}

.aleft {
  left: 0px;
}

.aright {
  right: 0px;
}

.block {
  display: block;
}

.none {
  display: none;
}

.pointer {
  cursor: pointer;
}

.italic {
  font-style: italic;
}

.normal {
  font-style: normal;
}

.of-scroll {
  overflow: scroll;
}

.of-hidden {
  overflow: hidden;
}

.of-auto {
  overflow: auto;
}

.of-visible {
  overflow: visible;
}

.clear {
  clear: both;
}

.cl-grey {
  color: #4d4d4d;
}

.cl-black {
  color: #000000;
}

.cl-white {
  color: #ffffff;
}

.bg-black {
  background-color: #000000;
}

.bg-white {
  background-color: #ffffff!important;
}

.m-auto {
  margin: auto;
}

.color-danger {
  color: var(--ion-brand-red);
}