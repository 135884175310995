@import "./_buttons.scss";
@import "../../theme/variables.scss";
@import "../../theme/variables.scss";

///////////////////////////////////////

// User App specific styles

///////////////////////////////////////
:root {
	--ion-print-btn-min-height: 50px;
}


.print__container {
	@extend .container;
	@include flexbox();
	@include flex-direction(column);
	// min-height: calc(100vh - #{--ion-navbar-height + 1});

	@media screen and (min-width: var(--ion-grid-float-breakpoint)) {
		min-height: calc(100vh - #{--ion-navbar-height + 30});
	}
}

.print__content {
//   min-height: 80vw;
	@include flex(1);
}

.print__content--center {
	@include flexbox();
	@include justify-content(center);
	@include align-items(center);
}

///////////////////////////////////////

// Page actions - print and select
// printer buttons

///////////////////////////////////////


.print__printer-id {
	font-weight: 500;
	line-height: 1.2em;
	margin-right: 3px;
}

.print__print-btn {
	min-height: var(--ion-print-btn-min-height);
	width: 100%;
	color: #fff;
	text-transform: unset;
	--background: var(--ion-color-green1);
	padding: 3px 0;
	&--method-text {
		vertical-align: auto !important;
	}
}


// we have 2 different setups for page actions

///////////////////////////////////////
// 1. Print Anywhere - show "print" button and "selected/change printer" button
///////////////////////////////////////


.page-actions .print__print-anywhere {

	.flexbox & {
		@include flexbox();
	}

	.print__print-btn {
		position: absolute;
		top: 10px;
		right: 10px;
		color: var(--ion-color-gray1);
		background-color: var(--ion-color-green1); // --ion-color-dark-shade
		min-width: 0;
		width: 30%;
		padding: var(--ion-padding-base-vertical);

		@media screen and (min-width: var(--ion-grid-float-breakpoint)) {
			width: 20%;
		}

		&::before {
			display: none;
			@extend .icon;
		}

		// show print icon instead of text label on smallest devices
		@media screen and (max-width: 320px) {
			position: relative;
			overflow: hidden;
			text-indent: 200%;
			color: transparent;

			&::before {
				content: var(--ion-icon-print);
				display: block;
				position: absolute;
				@include center(xy);
				font-family: "icons";
				color: #fff;
				text-indent: 0;
				font-size: 1.3em;
			}

			// &.qr-scanner::before {
			// 	content: unset;
			// }
		}

		.no-flexbox & {
			float: left;
		}
	}
}

.print__choose-printer {
	@include flexbox();
	width: 70%;
	margin-right: var(--ion-grid-gutter-width / 2);

	@media screen and (min-width: var(--ion-grid-float-breakpoint)) {
		width: 80%;
	}

	.no-flexbox & {
		float: left;
	}

	.print__printer-description {
		width: calc(100% - 60px);
	}
}

// choose printer
.print__choose-printer__btn {
	position: relative;
	width: 94%;
	@include justify-content(center);
	min-height: var(--ion-print-btn-min-height);
	padding: 4px 30px 4px 12px;
	text-align: left;
	@include text-overflow();
	color: var(--ion-text-color);
	border: 1px solid var(--ion-color-3);
	background: #fff;
	border-radius: 6px;
	transition:(all 0.2s);
	@include user-select(none);
	line-height: 1em;

	&:active {
		background-color: var(--ion-brand-blue);
        color: #fff;
	}

	// arrow
	&::before {
		content: var(--ion-icon-angle-up);
		position: absolute;
		font-family: "icons";
		right: 10px;
		font-size: 1.4em;
		@include center(y);
		top: 60%;
	}

	&:hover {
		cursor: pointer;
		color: var(--ion-text-color);
	}

	.no-touchevents &:hover {
		border-color: darken(#DCDCDC, 20%);
	}

	&:focus {
		outline: none;
		color: var(--ion-text-color);
	}
}

.print__choose-printer__label {
	margin-bottom: 3px;
	font-size: 0.8em;
	color: var(--ion-text-dimmed);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

///////////////////////////////////////
// 2. Print Later - show only print button
///////////////////////////////////////


.print__printer-description {
	display: inline-block;
	max-width: calc(100vw - 100px);
	line-height: 1.2em;
	padding-left: 6px;
	font-size: 0.9em;
	@include text-overflow();
}


.print__printer-list {
	display: block;
	margin-top: 1em;
	overflow-y: scroll;
}
