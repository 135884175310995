///////////////////////////////////////////////////////////////

// Printer id boxes - shows printer status and ID

///////////////////////////////////////////////////////////////

@import "variables";


///////////////////////////////////////////////////////////////

// outer box and text
.printer-id {
	display: inline-block;
	position: relative;
	height: 50px;
	width: 50px;
	background: #fff;
	color: var(--ion-color-gray2);
	border: 1px solid transparent;
	border-radius: 50%;
	font-weight: 500;
	border-color: var(--ion-color-gray2);
	text-align: center;
	line-height: 50px;
	font-size: 0.9em;
	text-transform: uppercase;
	vertical-align: middle;
	will-change: transform;
	transition:(border 0.2s, transform 0.2s)

}

.printer-id--sm {
	height: 17px;
	width: auto;
	margin-right: 3px;
	padding-left: 3px;
	padding-right: 3px;
	font-size: 0.85em;
	line-height: 15px;
}

.printer-id--online {
}

.printer-id--offline {
}

.printer-id--warning {
}

.printer-id--freedom {
}

// status icon
.printer-id__icon {
	position: absolute;
	bottom: -6px;
	right: -6px;
}

.printer-id--disabled {
	@include opacity(0.5);
}

a.printer-id:hover {
	cursor: pointer;
	color: var(--ion-text-color-light1);
	@include scale(1.1);
}


///////////////////////////////////////////////////////////////

// Status icons (online, offline, warning)

///////////////////////////////////////////////////////////////



// icons
.status-icon {
	display: inline-block;
	height: 8px;
	width: 8px;
	border-radius: 50%;

	&:focus {
		outline: none;
	}
}

// size variants
.status-icon--sm {
}

.status-icon--lg {
}


// online
.status-icon--online {
	background-color: var(--ion-brand-green);
}

// offline
.status-icon--offline {
	background-color: var(--ion-brand-red);
}

// warning
.status-icon--warning {
	background-color: var(--ion-brand-orange);
}


///////////////////////////////////////////////////////////////

// printer features

///////////////////////////////////////////////////////////////



.printer-id[data-has-features="true"] {
	padding-top: 12px;
	line-height: 1;
}

.printer-features {
	height: 1em;
	font-size: 10px; // use to control size of icons
	display: flex;
	justify-content: space-evenly;
	margin-top: 1px;
}

.printer-features--lg {
	height: 16px;
}

.printer-feature__icon {
	height: 1em;
}

.printer-feature__label {
	margin-left: 3px;
}
