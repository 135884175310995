.alt-header-color {
  background-color: aliceblue;
}

.alt-header-title {
  padding: 10px;
  background-color: #304050;
  color: white; 
}

.cell-maxmin-width {
  width: 30%;
  
}

.checkbox-lock {
  position: absolute;
  top: 12px;
  left: 10px;
  background-color: #d40a0a;
  border-radius: 50%;
  color: white;
  font-size: 80%;
  width: 20px;
  height: 20px;
  font-weight: 900;
}


.checkbox-lock-neutral {
  position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 50%;
    font-size: 70%;
    width: 20px;
    padding: 1px;
    height: 20px;
    top: 14px;
    left: 55.5%;
}

.tooltip-container {
  width: 20px;
  height: 20px;
  opacity: 1;
  position: absolute;
  top: 12px;
  left: calc(50% - 7px);
}
