///////////////////////////////////////

// list

///////////////////////////////////////

// general styling
th {
	text-align: left;
}

// we need an exstra element to have negative margins (full width) for lists
// .list {
// 	margin-left: var(--ion-grid-gutter-width / -2);
// 	margin-right: var(--ion-grid-gutter-width / -2);
// 	transition:(opacity 0.3s);

// 	@media screen and (min-width: var(--ion-grid-float-breakpoint)) {
// 		margin: 0;
// 	}
// }

// .list table {
// 	width: 100%;
// 	max-width: 100%;
// }

// .list th:first-child,
// .list td:first-child {
// 	padding-left: 15px;
// }

// .list th:last-child,
// .list td:last-child {
// 	padding-right: 15px;
// }

// .list__heading {
// 	text-align: left;
// 	padding: 5px 0;
// 	font-size: 1.1em;
// }

///////////////////////////////////////
// header
///////////////////////////////////////

.list th {
	font-weight: 600;

	a {
		color: var(--ion-text-color);
		font-size: 0.95em;
		text-decoration: none;

		&:hover {
			cursor: pointer;
		}
	}

	span {
		color: var(--ion-text-color);
		font-size: 0.95em;
	}

	// sorting
	&[data-sort] {
		white-space: nowrap;
	}

	&[data-sort="asc"] a::after,
	&[data-sort="desc"] a::after {
		@extend .icon;
		font-size: 0.8em;
		vertical-align: middle;
		margin-left: 5px;
		text-decoration: none!important;
	}

	&[data-sort="asc"] a::after {
		// content: var(--ion-icon-asc;
	}

	&[data-sort="desc"] a::after {
		// content: var(--ion-icon-desc;
	}
}

.list th,
.list td {
	padding: 15px 8px;
	border-top: 1px solid var(--ion-separator-color);
	// transition:(background 0.2s, border 0.2s);

	// small screen portrait portait
	@media only screen
	and (min-device-width: 320px)
	and (max-device-width: 568px)
	and (-webkit-min-device-pixel-ratio: 2) {
		padding: 8px 6px;
	}
}



// fixed column widths
.list .list__cb,
.list .list__rb {
	width: 50px;

	// checkboxes and radiobuttons need a bit of extra space (20px on each side) for people with fat thumbs :)
	.cb,
	.rb {
		margin-left: 5px;
		margin-right: 4px; // standard padding is 2 x 8 + 4 = 20px
	}
}

.list__printer-id {
	width: 50px;
	text-align: center;
}


// a dropdown sorting replaces the list header on smaller devices to save space
.list__sorting {
	text-align: right;

	@media screen and (min-width: var(--ion-grid-float-breakpoint)) {
		display: none;
	}
}

.list__sorting-label {
	color: var(--ion-link-color);
	font-weight: 600;
}

///////////////////////////////////////
// list item
///////////////////////////////////////

.list__item {
	transition:(background 0.25s);
}

// last list item needs bottom border
.list__item:last-child td {
	border-bottom: 1px solid var(--ion-separator-color);
}

.list__item--active td {
	background-color: var(--ion-brand-blue);
	color: #fff;

	.popover {
		color: var(--ion-text-color);
	}
}

.list__item--active .dropdown-toggle {
	color: #fff;
}

// active list items have highlighted borders
.list__item--active td,
.list__item--active + .list__item td,
.list__item--active:last-child td {
	border-top-color: var(--ion-separator-active-color);
}

.list__item--active .printer-id {
	border-color: var(--ion-brand-primary);
}

.list__item--clickable:hover {
	cursor: pointer;
}


.list--disabled {
	@include opacity(0.4);
	pointer-events: none;
	.list__item:hover {
		cursor: default !important;
	}
}

.list__item--disabled {
	background: var(--ion-list-item-disabled-bg);
	color: var(--ion-text-dimmed);
}

// favorite printers etc.
.list__item__options {
	position: relative;
}

// favorite printers are marked in the list
.list__item__favorite {
	position: absolute;
	top: -1px;
	right: 0;
	@include opacity(0);
	transition:(opacity 0.3s);

	&::after,
	&::before {
		content: "";
		position: absolute;
	}

	// background
	&::before {
		top: 0;
		right: 0;
		width: 0;
		height: 0;
		border-top: 24px solid var(--ion-gray-dark);
		border-left: 24px solid transparent;
	}

	// favorite icon
	&::after {
		top: 1px;
		right: 3px;
		content: var(--ion-icon-favorite-solid);
		font-family: "icons";
		font-size: 8px;
		color: #fff;
	}
}

.list__item--favorite .list__item__favorite {
	@include opacity(1);
}

.align-top {
	vertical-align: top;
}

///////////////////////////////////////
// list content
///////////////////////////////////////

// inline actions are in a overflow/dropdown menu

.list__item-icon {
	width: 30px;
	font-size: 1.3em;
}

.list__item-actions {
	margin: 0;
	text-align: right;
	@extend .list-unstyled !optional;

	> li {
		display: inline-block;
	}

	> li + li {
		margin-left: 20px;
	}

	> li > a {
		text-decoration: none;

		&:hover {
			cursor: pointer;
		}
	}

	i:before {
		vertical-align: middle;
	}
}

.list__item-overflow > .dropdown-toggle {
	font-size: 1.3em;
	color: var(--ion-text-color);
}

.list__item-overflow .dropdown-menu {
	top: 0;
}

.list__item-title {
	display: inline-block;
	line-height: 1.3em;
	font-weight: 500;
}

a.list__item-title {
	color: var(--ion-text-color);
	transition:(color 0.2s);

	&:hover {
		color: var(--ion-link-color);
		cursor: pointer;
	}

	.list__item--active & {
		color: #fff;
	}
}

.list__item-description {
	font-size: 0.85em;
	color: var(--ion-text-dimmed);

	.list__item--active & {
		@include opacity(0.8);
		color: #fff;
	}
}

.list + .list--spaced {
	margin-top: 3em;
}

///////////////////////////////////////

//  compact variation

///////////////////////////////////////

.list--compact {
	th,
	td {
		padding: 6px;
	}
}

///////////////////////////////////////

//  boxed list

///////////////////////////////////////

.list--boxed {
	margin-left: 0;
	margin-right: 0;

	table {
		border-collapse: separate;
		background: #fff;
		border-radius: var(--ion-border-radius-base);
		box-shadow:(1px 1px 10px 0px rgba(0,0,0,0.10));
	}

	tr:first-child td {
		border-top: 0;
	}
	tr:last-child td {
		border-bottom: 0;
	}
	// rounded corners
	tr:first-child td:first-child {
		border-top-left-radius: var(--ion-border-radius-base);
	}
	tr:first-child td:last-child {
		border-top-right-radius: var(--ion-border-radius-base);
	}
	tr:last-child td:first-child {
		border-bottom-left-radius: var(--ion-border-radius-base);
	}
	tr:last-child td:last-child {
		border-bottom-right-radius: var(--ion-border-radius-base);
	}

	.list__item--active td {
		background: var(--ion-document-list-active-bg);
		color: #fff;
	}
}

///////////////////////////////////////

//  hover

///////////////////////////////////////

.list--hover {
	.list__item {
		transition:(none);
	}

	.list__item:not(.list__item--disabled):hover {
		background-color: var(--ion-list-item-hover-bg);
		cursor: pointer;

		td,
		+ tr td {
			border-top-color: var(--ion-separator-hover-color);
		}
	}

	// last child has top and bottom border
	.list__item:not(.list__item--disabled):last-child:hover td {
		border-color: var(--ion-separator-hover-color);
	}
}
