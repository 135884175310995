[no-padding-horizontal] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

[no-padding-vertical] {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

[no-margin-horizontal] {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

[no-margin-vertical] {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.item-inner {
  padding: 0 !important;
}

ion-icon {
  min-width: 28px !important;
}

ion-list {
  margin: 0 !important;
}

ion-item {
  padding-left: 0 !important;
  padding-right: 0 !important;

  &[label-floating] {
    padding-top: var(--ion-item-padding-top);
  }
}

ion-label {
  margin: 0 !important;

  &[floating] {
    color: var(--ion-text-color-light1);
  }
}

ion-grid {
  padding: 0 !important;
}

ion-col {
  padding: 0 !important;
}

[background-dark-5] {
  background-color: var(--ion-background-color-dark5 !important);
}

ion-loading.print-job-spinner {
  ion-backdrop {
    opacity: 0.1;
  }
  .loading-wrapper {
    background-color: transparent !important;
    box-shadow: none;

    ion-spinner * {
      stroke: #000;
      fill: #000;
    }
  }
}